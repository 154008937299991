import { Col, Input, Row } from "antd";
import { FC } from "react";

import { ILongTermIncentive, IModal } from "@types";
import Modal from "@components/Modal";
import { useTypedSelector } from "@hooks";
import { useFetchComparatorDataQuery } from "@services";

const LongTermIncentiveData: FC<IModal> = ({ isVisible, setIsVisible }) => {
  const { offer } = useTypedSelector(state => state?.offers);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data } = useFetchComparatorDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    { skip: !offer?.id }
  );
  const { data: comparatorData } = data || {};
  const { lti } = comparatorData || {};

  return (
    <Modal
      mode="versions"
      footer={null}
      title={`Long Term Incentives - Grade ${offer?.offer_position_detail?.grade}`}
      isVisible={isVisible}
      width={855}
      setIsVisible={setIsVisible}
    >
      <div className="offers__modal">
        {lti?.length ? (
          lti?.map(
            ({
              id,
              equity_min,
              equity_mid,
              equity_max,
              plan,
            }: ILongTermIncentive) => (
              <div key={id} className="offers__modal__row">
                <div className="offers__modal__title">{`${plan?.name} (${plan?.type?.name})`}</div>
                <Row justify="space-between">
                  <Col span={7}>
                    <label>Min</label>
                    <Input
                      readOnly
                      value={equity_min || ""}
                      placeholder="Equity min"
                      size="large"
                    />
                  </Col>
                  <Col span={7}>
                    <label>Mid</label>
                    <Input
                      readOnly
                      value={equity_mid || ""}
                      placeholder="Equity mid"
                      size="large"
                    />
                  </Col>
                  <Col span={7}>
                    <label>Max</label>
                    <Input
                      readOnly
                      value={equity_max || ""}
                      placeholder="Equity max"
                      size="large"
                    />
                  </Col>
                </Row>
              </div>
            )
          )
        ) : (
          <div className="offers__modal__row">
            <h2>No Long Term Incentives Available</h2>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default LongTermIncentiveData;
