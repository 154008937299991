import { useRef, useState } from "react";
import { Col, message, Row } from "antd";
import { useDispatch } from "react-redux";

import Button from "@components/Button";
import Table from "@components/Table";
import {
  ErrorServices,
  gradeSetupApi,
  useDeleteJobGradeMutation,
  useFetchCompanyJobGradesQuery,
  useUploadJobGradesMutation,
} from "@services";
import { useTypedSelector } from "@hooks";
import { getColumns } from "./config";
import AddGrade from "./AddGrade";
import GradeSetupVersions from "./Versions";
import { IJobGrade } from "@types";
import Upload from "@components/Upload";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";

const GradeSetup = () => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  let jobGrade_id = useRef<any>(null);
  const { data: jobGradesData, isLoading } = useFetchCompanyJobGradesQuery({
    company_id,
    page,
  });
  const { data, pagination } = jobGradesData || {};
  const [deleteJobGrade, { isLoading: isDeleting }] =
    useDeleteJobGradeMutation();
  const [isVisible, setIsVisible] = useState(false);
  const [isVersionsModal, setIsVersionsModal] = useState(false);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [selectedJobGrade, setSelectedJobGrade] = useState<null | IJobGrade>(
    null
  );
  const [uploadFile, setUploadFile] = useState({
    file: null,
    active: false,
  });

  const [uploadJobGrades, { isLoading: isUploading }] =
    useUploadJobGradesMutation();

  const removeJobGrade = async (id: number) => {
    try {
      jobGrade_id.current = id;
      await deleteJobGrade({ id, company_id });
      message.success("Job Grade deleted successfully!");
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const editJobGrade = (jobGrade: IJobGrade) => {
    setSelectedJobGrade(jobGrade);
    setIsVisible(true);
  };

  const handleUpload = async () => {
    const { file, active }: { file: any; active: boolean } = uploadFile;
    setIsUploadModal(false);
    try {
      const formData = new FormData();
      formData.append("attachment", file!, file?.name!);
      const res = await uploadJobGrades({
        company_id,
        active,
        body: formData,
      }).unwrap();
      message.success(res?.message);
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    }
  };

  const download = (id?: number) => {
    dispatch(
      gradeSetupApi.endpoints.downloadJobGrades.initiate(
        {
          company_id,
          version_id: id || null,
        },
        {
          subscribe: false,
          forceRefetch: true,
        }
      )
    );
  };

  const columns = getColumns({
    deleteJobGrade: removeJobGrade,
    jobGrade_id,
    isDeleting,
    editJobGrade,
  });

  return (
    <>
      {isVisible && (
        <AddGrade
          selectedJobGrade={selectedJobGrade}
          setSelectedJobGrade={setSelectedJobGrade}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      )}

      {isVersionsModal && (
        <GradeSetupVersions
          isVisible={isVersionsModal}
          setIsVisible={setIsVersionsModal}
          download={download}
        />
      )}
      {isUploadModal && (
        <Upload
          title="Upload Job Grades"
          onSubmit={handleUpload}
          isVisible={isUploadModal}
          setIsVisible={setIsUploadModal}
          file={uploadFile}
          setFile={setUploadFile}
        />
      )}
      <Row>
        <Col span={24}>
          <div className="main-heading">Grade Setup</div>
        </Col>
      </Row>
      <Row className="mt-16 mb-20">
        <Col className="align-start" span={16}>
          {checkPermission(permissions.UPDATE_JOB_GRADE) ||
          checkPermission(permissions.CREATE_JOB_GRADE) ? (
            <Button
              isLoading={isUploading}
              variant="upload_client"
              onClick={() => setIsUploadModal(true)}
            >
              Upload
            </Button>
          ) : null}
          <Button onClick={() => download()} variant="download_client">
            Download
          </Button>{" "}
          <Button
            onClick={() => setIsVersionsModal(true)}
            variant="versions"
            icon={false}
          >
            Versions
          </Button>
          {checkPermission(permissions.CREATE_JOB_GRADE) && (
            <Button
              onClick={() => setIsVisible(true)}
              variant="versions"
              icon={false}
            >
              Create Job Grade
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Table
          data={data}
          isLoading={isLoading}
          columns={columns}
          pagination={true}
          count={pagination?.count}
          onChangePage={setPage}
          page={page}
        />
      </Row>
    </>
  );
};

export default GradeSetup;
