import { FC } from "react";
import { Redirect, Route } from "react-router-dom";

import { ssoUrl } from "@/constants";
import { useTypedSelector } from "@hooks";
import { checkPermission, isAdmin } from "@utils";
import { IRoute } from "./types";

const RouteWithSubRoutes: FC<IRoute> = route => {
  const { user } = useTypedSelector(state => state.auth);
  const isAuthenticated = user?.id;

  if (route.isPrivate && !isAuthenticated) {
    window.open(ssoUrl, "_self");
  }

  if (
    (isAuthenticated &&
      route?.permission &&
      !checkPermission(route?.permission)) ||
    (isAuthenticated &&
      !window.location.pathname.includes("client") &&
      route?.isPrivate &&
      !isAdmin(user?.role!)) ||
    (isAuthenticated &&
      window.location.pathname.includes("client") &&
      route?.isPrivate &&
      isAdmin(user?.role!))
  ) {
    const path = localStorage.getItem("prevPath");
    return <Redirect to={path || isAdmin(user?.role!) ? "/client" : "/"} />;
  } else {
    localStorage.setItem("prevPath", window.location.pathname);
    return (
      <Route
        path={route.path}
        render={props =>
          route.isPrivate ? (
            isAuthenticated ? (
              <route.component {...props} routes={route.routes} />
            ) : (
              <></>
            )
          ) : (
            <route.component {...props} routes={route.routes} />
          )
        }
      />
    );
  }
};

export default RouteWithSubRoutes;
