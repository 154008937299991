import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "antd";

import "./addOffer.less";
import CandidateDetails from "./CandidateDetails";
import OfferDetails from "./OfferDetails";
import OfferModeller from "./OfferModeller";
import { useTypedSelector } from "@hooks";
import { offersApi, useFetchOfferByIdQuery } from "@/services";
import { useParams } from "react-router-dom";

const components: any = {
  POSITION_DETAILS: <OfferDetails />,
  CANDIDATE_DETAILS: <CandidateDetails />,
  OFFER_MODELLER: <OfferModeller />,
};

const AddOffer = () => {
  const dispatch = useDispatch();
  const { offer_id: id } = useParams<{ offer_id: string }>();
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { offer: localOffer } = useTypedSelector(state => state.offers);
  const stage = localOffer?.stage;
  const is_create = window.location.pathname.includes("/create");
  useFetchOfferByIdQuery(
    {
      company_id,
      id,
    },
    { skip: is_create }
  );

  useEffect(() => {
    if (!is_create) {
      dispatch(
        offersApi.endpoints.fetchOfferById.initiate(
          {
            company_id,
            id,
          },
          {
            subscribe: false,
            forceRefetch: true,
          }
        )
      );
    }
    // return () => {
    //   dispatch(resetOffers());
    // };
  }, [company_id, dispatch, id, is_create]);

  return (
    <div className="offers">
      <Row>
        <Col span={24}>
          <div className="main-heading">{`${
            !is_create ? "View" : "Create"
          } Offer`}</div>
        </Col>
      </Row>
      <Row className="mt-32 offers__steps">
        <div className={`offers__steps__step  offers__steps__step--active`}>
          <div className="offers__steps__step__circle">
            <span className="offers__steps__step__circle__num">1</span>
          </div>
          <div className="offers__steps__step__title">
            Offer
            <br /> Details
          </div>
          <div
            className={`offers__steps__step__border ${
              stage &&
              stage !== "POSITION_DETAILS" &&
              "offers__steps__step__border--active"
            }`}
          />
        </div>
        <div
          className={`offers__steps__step  ${
            stage &&
            stage !== "POSITION_DETAILS" &&
            "offers__steps__step--active"
          }`}
        >
          <div className="offers__steps__step__circle">
            <span className="offers__steps__step__circle__num">2</span>
          </div>
          <div className="offers__steps__step__title">
            Candidate
            <br /> Details
          </div>
          <div
            className={`offers__steps__step__border ${
              stage &&
              stage !== "CANDIDATE_DETAILS" &&
              stage !== "POSITION_DETAILS" &&
              "offers__steps__step__border--active"
            }`}
          />
        </div>
        <div
          className={`offers__steps__step  ${
            stage &&
            stage !== "CANDIDATE_DETAILS" &&
            stage !== "POSITION_DETAILS" &&
            "offers__steps__step--active"
          }`}
        >
          <div className="offers__steps__step__circle">
            <span className="offers__steps__step__circle__num">3</span>
          </div>
          <div className="offers__steps__step__title">
            Salary Offer
            <br /> Modeller
          </div>
        </div>
      </Row>

      <div className="offers__form mt-32">
        {localOffer ? components[stage!] : <OfferDetails />}
      </div>
    </div>
  );
};

export default AddOffer;
