import { FC, useState, useEffect } from "react";

import Table from "@components/Table";
import Modal from "@components/Modal";
import { IModal, IVersion } from "@types";
import {
  ErrorServices,
  useFetchCashAllowanceVersionsQuery,
  useUpdateCashAllowanceVersionMutation,
} from "@services";
import { getVersionsColumns } from "../config";
import { useTypedSelector } from "@hooks";

interface ICashAllowanceVersions extends IModal {
  download: (id?: number | undefined) => void;
}

const CashAllowanceVersions: FC<ICashAllowanceVersions> = ({
  isVisible,
  setIsVisible,
  download,
}) => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data: versions, isLoading } = useFetchCashAllowanceVersionsQuery({
    page: 1,
    company_id,
  });
  const [updateCashAllowanceVersion] = useUpdateCashAllowanceVersionMutation();
  const { data } = versions || {};
  const [versionId, setVersionId] = useState<number | null>(null);

  useEffect(() => {
    if (data?.length) {
      const { id } = data?.find(
        (version: IVersion) => version?.is_active === "TRUE"
      );
      setVersionId(id);
    }
  }, [data]);

  const handleActive = async (id: number) => {
    try {
      setVersionId(id);
      await updateCashAllowanceVersion({ id, company_id }).unwrap();
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const versionsColumns = getVersionsColumns({
    handleActive,
    versionId,
    download,
  });
  return (
    <Modal
      mode="versions"
      footer={null}
      title="Previous Versions of Cash Allowances"
      isVisible={isVisible}
      width={855}
      setIsVisible={setIsVisible}
    >
      <Table
        isLoading={isLoading}
        data={data}
        columns={versionsColumns}
        pagination={false}
      />
    </Modal>
  );
};

export default CashAllowanceVersions;
