import { useDispatch } from "react-redux";
import { Row, Col, Input } from "antd";
import _ from "lodash";

import { ReactComponent as PlusIcon } from "@assets/images/plus-with-bg.svg";
import { useTypedSelector } from "@hooks";
import { handleOfferModeller } from "@store/offers";
import { getCurrencyTotal, round } from "@utils";

const SignOnBonus = () => {
  const dispatch = useDispatch();

  const {
    offerModeller: { offer_sign_on_bonus },
    currencyRate,
    currencyName,
    offer,
  } = useTypedSelector(state => state.offers);
  const readOnly = offer && offer?.status !== "DRAFTED" ? true : false;
  const currencyCode = currencyName?.split("(")[1]?.replace(")", "") || "";

  const { current_currency_total, proposed_currency_total } = getCurrencyTotal(
    offer_sign_on_bonus,
    currencyRate,
    []
  );
  const labelColSpan = currencyCode ? 10 : 12;
  const currencyColSpan = currencyCode ? 7 : 12;

  const addMoreLocally = (is_current: boolean) => {
    const newCurrent = {
      is_current,
      value: null,
      name: null,
      id: +_.uniqueId(),
      readonly: true,
    };
    dispatch(
      handleOfferModeller({
        key: "offer_sign_on_bonus",
        data: {
          bonuses: [...offer_sign_on_bonus.bonuses, newCurrent],
        },
      })
    );
  };

  const handleChangeNewField = (
    id: number,
    value: number | string,
    type: "name" | "value"
  ) => {
    dispatch(
      handleOfferModeller({
        key: "offer_sign_on_bonus",
        data: {
          bonuses: offer_sign_on_bonus?.bonuses?.map(bonus =>
            bonus?.id === id ? { ...bonus, [type]: value } : bonus
          ) as any,
        },
      })
    );
  };

  const removeField = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_sign_on_bonus",
        data: {
          bonuses: offer_sign_on_bonus?.bonuses?.map(bonus =>
            bonus?.id === id ? { ...bonus, readonly: false } : bonus
          ) as any,
        },
      })
    );
  };

  const removeBonus = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_sign_on_bonus",
        data: {
          bonuses: offer_sign_on_bonus?.bonuses?.filter(
            bonus => bonus?.id !== id
          ) as any,
        },
      })
    );
  };

  return (
    <Row className="salary-offer">
      <Col className="salary-offer__parent-col" span={12}>
        <Row className="salary-offer__header-row">
          <Col span={12}>
            <div className="sub-heading">Current</div>
          </Col>
        </Row>
        <Row className="salary-offer__content-row salary-offer__content-row--last">
          {/* Current-Cols */}
          <Col className="salary-offer__content-row__col" span={labelColSpan}>
            <div className="salary-offer__content-row__field-upper"></div>
            {offer_sign_on_bonus?.bonuses
              ?.filter(({ is_current }) => is_current)
              ?.map(({ name, id, readonly }) => (
                <div key={id} className="salary-offer__content-row__field">
                  {name?.length && !readonly! ? (
                    <label>{name}</label>
                  ) : (
                    <Input
                      readOnly={readOnly}
                      autoFocus
                      onChange={e =>
                        handleChangeNewField(id!, e?.target?.value, "name")
                      }
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          removeField(id!);
                        }
                      }}
                      value={name || ""}
                      placeholder="Value here..."
                      size="large"
                    />
                  )}
                  {!readOnly && (
                    <div
                      className="salary-offer__content-row__field__remove"
                      onClick={() => removeBonus(id!)}
                    >
                      <PlusIcon fill="#000" />
                    </div>
                  )}
                </div>
              ))}
            {!readOnly ? (
              <div
                onClick={() => addMoreLocally(true)}
                className="salary-offer__content-row__field--add-more"
              >
                <PlusIcon fill="#4FAA89" />
                <span>Add more</span>
              </div>
            ) : (
              <div className="salary-offer__content-row__field--add-more" />
            )}

            <div className="salary-offer__content-row__field salary-offer__content-row__field--t`ot`al">
              <label>Total Guaranteed Cash</label>
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total" />
          </Col>
          <Col
            className="salary-offer__content-row__col"
            span={currencyColSpan}
          >
            <div className="salary-offer__content-row__field-upper">
              {offer?.offer_position_detail?.currency?.code}
            </div>
            {offer_sign_on_bonus?.bonuses
              ?.filter(({ is_current }) => is_current)
              ?.map(({ name, value, id }) => (
                <div key={id} className="salary-offer__content-row__field">
                  <Input
                    readOnly={readOnly}
                    name={name!}
                    type="number"
                    value={value || ""}
                    onChange={e =>
                      handleChangeNewField(id!, +e.target.value, "value")
                    }
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              ))}
            <div className="salary-offer__content-row__field--add-more" />
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <Input
                readOnly
                value={offer_sign_on_bonus?.current_total_bonus || ""}
                name="current_total_lti"
                placeholder="Value here..."
                size="large"
              />
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total" />
          </Col>
          {currencyCode ? (
            <Col className="salary-offer__content-row__col" span={7}>
              <div className="salary-offer__content-row__field-upper">
                {currencyCode}
              </div>
              {offer_sign_on_bonus?.bonuses
                ?.filter(({ is_current }) => is_current)
                ?.map(({ id, value }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    <Input
                      value={round(value! * currencyRate!) || ""}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                    />
                  </div>
                ))}
              <div className="salary-offer__content-row__field--add-more" />
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  value={current_currency_total || ""}
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total" />
            </Col>
          ) : null}
        </Row>
      </Col>
      <Col className="salary-offer__parent-col" span={12}>
        <Row className="salary-offer__header-row">
          <Col span={12}>
            <div className="sub-heading">Proposed</div>
          </Col>
        </Row>
        <Row className="salary-offer__content-row salary-offer__content-row--last">
          {/* Proposed-Cols */}

          <Col className="salary-offer__content-row__col" span={labelColSpan}>
            <div className="salary-offer__content-row__field-upper"></div>
            {offer_sign_on_bonus?.bonuses
              ?.filter(({ is_current }) => !is_current)
              ?.map(({ name, id, readonly }) => (
                <div key={id} className="salary-offer__content-row__field">
                  {name?.length && !readonly! ? (
                    <label>{name}</label>
                  ) : (
                    <Input
                      readOnly={readOnly}
                      autoFocus
                      onChange={e =>
                        handleChangeNewField(id!, e?.target?.value, "name")
                      }
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          removeField(id!);
                        }
                      }}
                      value={name || ""}
                      placeholder="Value here..."
                      size="large"
                    />
                  )}
                  {!readOnly && (
                    <div
                      className="salary-offer__content-row__field__remove"
                      onClick={() => removeBonus(id!)}
                    >
                      <PlusIcon fill="#000" />
                    </div>
                  )}
                </div>
              ))}

            {!readOnly ? (
              <div
                onClick={() => addMoreLocally(false)}
                className="salary-offer__content-row__field--add-more"
              >
                <PlusIcon fill="#4FAA89" />
                <span>Add more</span>
              </div>
            ) : (
              <div className="salary-offer__content-row__field--add-more" />
            )}

            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <label>Total Package</label>
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <label>Difference</label>
            </div>
          </Col>
          <Col
            className="salary-offer__content-row__col"
            span={currencyColSpan}
          >
            <div className="salary-offer__content-row__field-upper">
              {offer?.offer_position_detail?.currency?.code}
            </div>

            {offer_sign_on_bonus?.bonuses
              ?.filter(({ is_current }) => !is_current)
              ?.map(({ name, value, id }) => (
                <div key={id} className="salary-offer__content-row__field">
                  <Input
                    readOnly={readOnly}
                    name={name!}
                    type="number"
                    value={value || ""}
                    onChange={e =>
                      handleChangeNewField(id!, +e.target.value, "value")
                    }
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              ))}

            <div className="salary-offer__content-row__field--add-more" />
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <Input
                readOnly
                name="proposed_total_lti"
                value={offer_sign_on_bonus?.proposed_total_bonus || ""}
                placeholder="Value here..."
                size="large"
              />
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total" />
          </Col>
          {currencyCode ? (
            <Col className="salary-offer__content-row__col" span={7}>
              <div className="salary-offer__content-row__field-upper">
                {currencyCode}
              </div>
              {offer_sign_on_bonus?.bonuses
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ id, value }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    <Input
                      value={round(value! * currencyRate!) || ""}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                    />
                  </div>
                ))}

              <div className="salary-offer__content-row__field--add-more" />
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  value={proposed_currency_total || ""}
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  value={current_currency_total - proposed_currency_total || ""}
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default SignOnBonus;
