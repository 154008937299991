import { OfferModellerAddedField } from "@types";
import { round } from "./round";

export const getCurrencyTotal = (
  data: any,
  currency_rate: number | null,
  black_list: string[]
): { current_currency_total: any; proposed_currency_total: any } => {
  let current_currency_total = 0;
  let proposed_currency_total = 0;
  for (let key in data) {
    if (data[key] && currency_rate && !black_list?.includes(key)) {
      if (Array.isArray(data[key])) {
        // eslint-disable-next-line no-loop-func
        data[key].forEach((item: OfferModellerAddedField) => {
          if (item?.is_current && item?.value) {
            current_currency_total += item.value * currency_rate;
          } else if (!item?.is_current && item?.value) {
            proposed_currency_total += item.value * currency_rate;
          }
        });
      } else {
        if (key?.includes("current") && !key?.includes("_total")) {
          current_currency_total += data[key] * currency_rate;
        } else if (key?.includes("proposed") && !key?.includes("_total")) {
          proposed_currency_total += data[key] * currency_rate;
        }
      }
    }
  }
  return {
    current_currency_total: round(current_currency_total),
    proposed_currency_total: round(proposed_currency_total),
  };
};
