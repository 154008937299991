import { useEffect, useState } from "react";
import { Col, message, Row } from "antd";
import { useDispatch } from "react-redux";

import Button from "@components/Button";
import Upload from "@components/Upload";
import { getVersionsColumns } from "./config";
import Table from "@components/Table";
import {
  ErrorServices,
  marketDataApi,
  useFetchMarketDataVersionsQuery,
  useUpdateMarketDataVersionMutation,
  useUploadMarketDataMutation,
} from "@services";
import { IVersion } from "@types";
import { useTypedSelector } from "@hooks";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";

const MarketData = () => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const dispatch = useDispatch();
  const { data: versions, isLoading } = useFetchMarketDataVersionsQuery({
    page: 1,
    company_id,
  });
  const [updateMarketDataVersion] = useUpdateMarketDataVersionMutation();
  const { data } = versions || {};

  const [isUploadModal, setIsUploadModal] = useState(false);
  const [currentId, setCurrentId] = useState<null | number>(null);
  const [uploadFile, setUploadFile] = useState({
    file: null,
    active: false,
  });
  const [uploadMarketData, { isLoading: isUploading }] =
    useUploadMarketDataMutation();

  useEffect(() => {
    if (data?.length) {
      const { id } = data?.find(
        (version: IVersion) => version?.is_active === "TRUE"
      );
      setCurrentId(id);
    }
  }, [data]);

  const handleActive = async (id: number) => {
    try {
      setCurrentId(id);
      await updateMarketDataVersion({ id, company_id }).unwrap();
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const handleUpload = async () => {
    const { file, active }: { file: any; active: boolean } = uploadFile;
    setIsUploadModal(false);
    try {
      const formData = new FormData();
      formData.append("attachment", file!, file?.name!);
      const res = await uploadMarketData({
        company_id,
        active,
        body: formData,
      }).unwrap();
      message.success(res?.message);
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    }
  };

  const download = (id?: number) => {
    dispatch(
      marketDataApi.endpoints.downloadMarketData.initiate(
        {
          company_id,
          version_id: id || null,
        },
        {
          subscribe: false,
          forceRefetch: true,
        }
      )
    );
  };

  const versionsColumns = getVersionsColumns({
    handleActive,
    currentId,
    download,
  });

  return (
    <>
      {isUploadModal && (
        <Upload
          title="Upload Internal Payroll Data"
          onSubmit={handleUpload}
          isVisible={isUploadModal}
          setIsVisible={setIsUploadModal}
          file={uploadFile}
          setFile={setUploadFile}
        />
      )}
      <Row>
        <Col span={24}>
          <div className="main-heading">Market Data</div>
        </Col>
      </Row>
      <Row className="mt-16 mb-20">
        <Col className="align-start" span={16}>
          {checkPermission(permissions.CREATE_MARKET_DATA) ||
          checkPermission(permissions.UPDATE_MARKET_DATA) ? (
            <Button
              onClick={() => setIsUploadModal(true)}
              isLoading={isUploading}
              variant="upload_client"
            >
              Upload
            </Button>
          ) : null}
          <Button onClick={() => download()} variant="download_client">
            Download
          </Button>
        </Col>
      </Row>
      <Row>
        <Table
          isLoading={isLoading}
          data={data}
          columns={versionsColumns}
          pagination={false}
        />
      </Row>
    </>
  );
};

export default MarketData;
