import { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row, Select } from "antd";

import Tabs from "@components/Tabs";
import JobInfo from "./JobInfo";
import SalaryOfferModeller from "./SalaryOfferModeller";
import Button from "@components/Button";
import CandidateDetails from "../CandidateDetails";
import OfferDetails from "../OfferDetails";
import InternalComparatorData from "./InternalComparatorData";
import ExternalComparatorData from "./ExternalComparatorData";
import LongTermIncentiveData from "./LongTermIncentiveData";
import { offersApi, useFetchCurrenciesQuery } from "@services";
import { ICurrency } from "@types";
import { handleCurrency, removeCurrency } from "@store/offers";
import { useTypedSelector } from "@hooks";

const { Option } = Select;

interface IComponent {
  1: JSX.Element;
  2: JSX.Element;
  3: JSX.Element;
  4: JSX.Element;
}

type Keys = keyof IComponent;

const components: IComponent = {
  1: <JobInfo />,
  2: <CandidateDetails offerModeller={true} />,
  3: <OfferDetails offerModeller={true} />,
  4: <SalaryOfferModeller />,
};

const OfferModeller = () => {
  const dispatch = useDispatch();
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const [selectedTab, setSelectedTab] = useState(4);
  const [internalData, setInternalData] = useState(false);
  const [externalData, setExternalData] = useState(false);
  const [ltiData, setLtiData] = useState(false);
  const { currencyName, offer } = useTypedSelector(state => state?.offers);
  const { data: currenciesData, isLoading: isFetchingCurrencies } =
    useFetchCurrenciesQuery(null);
  const { data: currencies } = currenciesData || {};
  const onClickTab = (id: number) => setSelectedTab(id);

  return (
    <div className="offers__form__offerModeller">
      <InternalComparatorData
        isVisible={internalData}
        setIsVisible={setInternalData}
      />

      <ExternalComparatorData
        isVisible={externalData}
        setIsVisible={setExternalData}
      />

      <LongTermIncentiveData isVisible={ltiData} setIsVisible={setLtiData} />

      {selectedTab === 4 ? (
        <Row
          justify="space-between"
          className="offers__form__offerModeller__headerBtns"
        >
          <Col className="offers__form__offerModeller__btns-col" span={10}>
            <Button
              onClick={() => setLtiData(true)}
              icon={false}
              variant="upload_client"
            >
              {`Long Term Incentives - Grade ${
                offer?.offer_position_detail?.grade || ""
              }`}
            </Button>
            <Button
              onClick={() => setExternalData(true)}
              icon={false}
              variant="download_client"
            >
              External Comparator Data
            </Button>
            <Button
              onClick={() => setInternalData(true)}
              variant="versions"
              icon={false}
            >
              Interal Comparator Data
            </Button>
          </Col>
          <Col span={6}>
            <Select
              value={currencyName || undefined}
              loading={isFetchingCurrencies}
              allowClear={true}
              onClear={() => dispatch(removeCurrency())}
              onChange={(currency: string) => {
                const { id, code, name } = JSON.parse(currency);
                dispatch(
                  handleCurrency({
                    id,
                    code: `${name} (${code})`,
                  })
                );
                dispatch(
                  offersApi.endpoints.fetchCurrencyRate.initiate(
                    {
                      code: id,
                      company_id,
                      offer_id: offer?.id,
                    },
                    {
                      subscribe: false,
                      forceRefetch: true,
                    }
                  )
                );
              }}
              showArrow={true}
              showSearch={true}
              size="large"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Convert Currency"
            >
              {currencies
                ?.slice()
                ?.sort(function (a: any, b: any) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                ?.map((currency: ICurrency) => (
                  <Option key={currency.id} value={JSON.stringify(currency)}>
                    {`${currency.name} (${currency.code})`}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
      ) : null}
      <Row className="offers__form__gap" />
      <Row className="offers__form__tabs">
        <Tabs
          route={false}
          selectedTabId={selectedTab}
          onClickTab={onClickTab}
          tabs={[
            {
              id: 1,
              title: "Job Information",
            },

            {
              id: 2,
              title: "Candidate Details",
            },
            {
              id: 3,
              title: "Offer Details",
            },
            {
              id: 4,
              title: "Salary Offer Modeller",
            },
          ]}
        />
      </Row>
      {components[selectedTab as Keys]}
    </div>
  );
};

export default OfferModeller;
