import { useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import { Button, Col, Input, message, Row, Select } from "antd";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import { useTypedSelector } from "@hooks";
import { types } from "./config";
import {
  ErrorServices,
  useCreateOfferDetailsMutation,
  useFetchAllBusinessUnitsQuery,
  useFetchAllCompanyJobGradesQuery,
  useFetchAllJobFunctionsQuery,
  useFetchCurrenciesQuery,
  useUpdateOfferDetailsMutation,
  useFetchCompanyCountriesQuery,
} from "@services";
import { handlePositionDetails, handleStage } from "@store/offers";
import {
  IBusinessUnitItem,
  ICountry,
  ICurrency,
  IJobFunctionItem,
  IJobGrade,
  IRegion,
  ISubJobFunctionItem,
} from "@types";

const { Option } = Select;

const OfferDetails = ({ offerModeller }: { offerModeller?: boolean }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const draft = useRef<boolean>(false);
  const is_create = window.location.pathname.includes("/create");
  const { offer } = useTypedSelector(state => state?.offers);
  const readOnly =
    offerModeller || (offer && offer?.status !== "DRAFTED") ? true : false;
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { positionDetails } = useTypedSelector(state => state?.offers);
  const {
    grade,
    job_title,
    type,
    region,
    country_id,
    job_function,
    sub_job_function_id,
    reporting_grade,
    position_owner,
    city,
    grade_type,
    reporting_title,
    cost_centre,
    is_critical,
    position_comment,
    business_unit,
    currency_id,
  } = positionDetails;
  const { data: gradesData, isLoading: isLoadingGrades } =
    useFetchAllCompanyJobGradesQuery({ company_id });
  const { data: countriesData } = useFetchCompanyCountriesQuery({
    company_id,
  });
  const { data: companyCountries } = countriesData || {};
  const { data: grades } = gradesData || {};
  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions } =
    useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};
  const { data: businessUnitsData, isLoading: isLoadingBusinessUnits } =
    useFetchAllBusinessUnitsQuery({ company_id });
  const { data: businessUnits } = businessUnitsData || {};
  const [subJobFunctions, setSubJobFunctions] = useState<ISubJobFunctionItem[]>(
    []
  );
  const { data: currenciesData, isLoading: isFetchingCurrencies } =
    useFetchCurrenciesQuery(null);
  const { data: currencies } = currenciesData || {};
  const [regions, setRegions] = useState<Partial<IRegion>[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [createOfferDetails, { isLoading: isCreatingOfferDetails }] =
    useCreateOfferDetailsMutation();
  const [updateOfferDetails, { isLoading: isUpdatingOfferDetails }] =
    useUpdateOfferDetailsMutation();
  const disabled =
    !type ||
    !grade_type ||
    !grade ||
    !job_function ||
    !sub_job_function_id ||
    !country_id ||
    !region ||
    !business_unit ||
    !job_title ||
    !currency_id;

  useEffect(() => {
    if (!is_create) {
      const jobFunction = jobFunctions?.find(
        (func: Partial<IJobFunctionItem>) => func.id === job_function?.id
      );
      const selected_grade = grades?.find(
        (x: Partial<IJobGrade>) => x.grade === grade
      );
      const bu = businessUnits?.find(
        (businessUnit: Partial<IBusinessUnitItem>) =>
          businessUnit.id === business_unit?.id
      );
      setSubJobFunctions(jobFunction?.job_sub_functions);
      setRegions(bu?.regions);
      setCountries(
        selected_grade?.is_global ? companyCountries : selected_grade?.countries
      );
    }
  }, [
    businessUnits,
    business_unit?.id,
    companyCountries,
    grade,
    grades,
    is_create,
    jobFunctions,
    job_function?.id,
  ]);

  const handleChange = (event: any) => {
    if (readOnly) return;
    dispatch(
      handlePositionDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleSubmit = async (is_draft: boolean) => {
    if (offer && offer.status !== "DRAFTED") {
      return dispatch(handleStage("CANDIDATE_DETAILS"));
    }
    try {
      draft.current = is_draft;
      const body: any = {
        ...positionDetails,
        business_unit_id: business_unit?.id,
        job_function_id: job_function?.id,
        region_id: region?.id,
        is_draft,
      };
      delete body["business_unit"];
      delete body["job_function"];
      delete body["region"];
      delete body["currency"];

      if (offer?.status === "DRAFTED") {
        await updateOfferDetails({ company_id, id: offer?.id, body }).unwrap();
      } else {
        await createOfferDetails({ company_id, body }).unwrap();
      }
      message.success("Offer details saved successfully");
    } catch (err) {
      console.log(err);
      ErrorServices(err);
    }
  };

  return (
    <>
      <Row className="offers__form__header">
        <div className="sub-heading">Offered Position Details</div>
      </Row>
      <div className="offers__form__fields">
        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <label>Type</label>
            <Select
              size="large"
              showArrow
              placeholder="Select offer type from here..."
              showSearch={false}
              onChange={value =>
                !readOnly && handleChange({ target: { name: "type", value } })
              }
              value={type || undefined}
            >
              {types?.map(({ id, label, value }) => (
                <Option key={id} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <label>Job Function</label>
            <Select
              loading={isLoadingJobFunctions}
              size="large"
              showArrow
              placeholder="Select job function from here..."
              showSearch={false}
              onChange={(serialized_jobFunction: string) => {
                if (readOnly) return;
                const jobFunction = JSON.parse(serialized_jobFunction);
                handleChange({
                  target: {
                    name: "job_function",
                    value: { id: jobFunction?.id, name: jobFunction?.name },
                  },
                });
                dispatch(
                  handlePositionDetails({
                    sub_job_function_id: null,
                  })
                );
                setSubJobFunctions(jobFunction?.job_sub_functions);
              }}
              value={job_function?.name || undefined}
            >
              {jobFunctions?.map((jobFunction: Partial<IJobFunctionItem>) => (
                <Option
                  key={jobFunction?.id}
                  value={JSON.stringify(jobFunction)}
                >
                  {jobFunction?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <label>Sub-Job Function</label>
            <Select
              disabled={!job_function?.id}
              size="large"
              showArrow
              placeholder="Select sub-job function from here..."
              showSearch={false}
              onChange={value =>
                !readOnly &&
                handleChange({ target: { name: "sub_job_function_id", value } })
              }
              value={sub_job_function_id || undefined}
            >
              {subJobFunctions
                ?.slice()
                ?.sort(function (a: any, b: any) {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                ?.map(({ id, name }: ISubJobFunctionItem) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <label>Business Unit</label>
            <Select
              loading={isLoadingBusinessUnits}
              size="large"
              showArrow
              placeholder="Select business unit from here..."
              showSearch={false}
              value={business_unit?.name || undefined}
              onChange={(serialized_businessUnit: string) => {
                if (readOnly) return;
                const businessUnit = JSON.parse(serialized_businessUnit);
                handleChange({
                  target: {
                    name: "business_unit",
                    value: { id: businessUnit?.id, name: businessUnit?.name },
                  },
                });
                dispatch(
                  handlePositionDetails({
                    region: null,
                  })
                );
                setRegions(businessUnit?.regions);
              }}
            >
              {businessUnits?.map(
                (businessUnit: Partial<IBusinessUnitItem>) => (
                  <Option
                    key={businessUnit?.id}
                    value={JSON.stringify(businessUnit)}
                  >
                    {businessUnit?.name}
                  </Option>
                )
              )}
            </Select>
          </Col>
          <Col span={7}>
            <label>Region</label>
            <Select
              disabled={!business_unit?.id}
              size="large"
              showArrow
              placeholder="Select region from here..."
              showSearch={false}
              value={region?.name || undefined}
              onChange={(region: string) => {
                if (readOnly) return;
                const { name, id, countries } = JSON.parse(region);
                handleChange({
                  target: {
                    name: "region",
                    value: { name, id },
                  },
                });
                handleChange({
                  target: {
                    name: "country_id",
                    value: null,
                  },
                });
                setCountries(countries);
              }}
            >
              {regions?.map((region: Partial<IRegion>) => (
                <Option key={region?.id} value={JSON.stringify(region)}>
                  {region?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <label>Current Currency</label>
            <Select
              value={currency_id || undefined}
              loading={isFetchingCurrencies}
              onChange={value =>
                !readOnly &&
                handleChange({
                  target: { name: "currency_id", value },
                })
              }
              showArrow={true}
              showSearch={true}
              size="large"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select currency from here..."
            >
              {currencies?.map(({ id, name, code }: ICurrency) => (
                <Option key={id} value={id}>
                  {`${name} (${code})`}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={7}>
            <label>Grade</label>
            <Select
              loading={isLoadingGrades}
              size="large"
              showArrow
              placeholder="Select grade from here..."
              showSearch={false}
              value={grade || undefined}
              onChange={(grade: string) => {
                !readOnly &&
                  handleChange({
                    target: {
                      name: "grade",
                      value: grade,
                    },
                  });
              }}
            >
              {grades?.map((grade: Partial<IJobGrade>) => (
                <Option key={grade?.id!} value={grade?.grade!}>
                  {grade?.grade}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <label>Country</label>
            <Select
              disabled={!region}
              size="large"
              showArrow
              placeholder="Select country from here..."
              showSearch={false}
              value={country_id || undefined}
              onChange={value =>
                !readOnly &&
                handleChange({ target: { name: "country_id", value } })
              }
            >
              {countries?.map((country: ICountry) => (
                <Option key={country?.id} value={country.id}>
                  {country?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <label>City</label>
            <Input
              readOnly={readOnly}
              size="large"
              name="city"
              placeholder="Enter city here..."
              onChange={handleChange}
              value={city || undefined}
            />
          </Col>
        </Row>
      </div>
      <div className="offers__form__fields">
        <div className="sub-heading">Other Details</div>
        <Row justify="space-between" className="mt-24 mb-24">
          <Col span={7}>
            <label>Job Title</label>
            <Input
              readOnly={readOnly}
              name="job_title"
              onChange={handleChange}
              value={job_title || ""}
              size="large"
              placeholder="Enter job title here..."
            />
          </Col>
          <Col span={7}>
            <label>Position Owner</label>
            <Input
              readOnly={readOnly}
              name="position_owner"
              onChange={handleChange}
              value={position_owner || ""}
              size="large"
              placeholder="Enter position owner here..."
            />
          </Col>
          <Col span={7}>
            <label>Job Grade Type</label>
            <Input
              readOnly={readOnly}
              size="large"
              name="grade_type"
              onChange={handleChange}
              placeholder="Enter job grade type here..."
              value={grade_type || ""}
            />
          </Col>
        </Row>

        <Row justify="space-between" className="mb-24">
          <Col span={7}>
            <label>Reporting to Grade</label>
            <Select
              size="large"
              showArrow
              placeholder="Select reporting grade from here..."
              showSearch={false}
              value={reporting_grade || undefined}
              onChange={value =>
                !readOnly &&
                handleChange({ target: { name: "reporting_grade", value } })
              }
            >
              {grades?.map(({ id, grade }: Partial<IJobGrade>) => (
                <Option key={id} value={grade!}>
                  {grade}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <label>Reporting Title</label>
            <Input
              readOnly={readOnly}
              name="reporting_title"
              onChange={handleChange}
              value={reporting_title || ""}
              size="large"
              placeholder="Enter reporting title here..."
            />
          </Col>
          <Col span={7}>
            <label>Cost Centre</label>
            <Input
              readOnly={readOnly}
              name="cost_centre"
              onChange={handleChange}
              value={cost_centre || ""}
              size="large"
              placeholder="Enter cost center here..."
            />
            <br />
            <Checkbox
              checked={is_critical}
              onChange={event =>
                handleChange({
                  target: { name: "is_critical", value: event.target.checked },
                })
              }
              className="offers__form__fields__checkbox mt-24"
            >
              Critical Role?
            </Checkbox>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label>Position Comment</label>
            <TextArea
              readOnly={readOnly}
              name="position_comment"
              onChange={handleChange}
              value={position_comment || ""}
              rows={5}
              placeholder="Enter your comment here..."
            />
          </Col>
        </Row>
        {offerModeller ? null : (
          <Row className="offers__form__fields__btns">
            <Button
              disabled={disabled}
              onClick={() => handleSubmit(false)}
              size="large"
              type="primary"
            >
              {(isCreatingOfferDetails || isUpdatingOfferDetails) &&
              !draft?.current ? (
                <LoadingOutlined className="spinner" />
              ) : (
                "Next"
              )}
            </Button>
            <Button
              disabled={disabled}
              onClick={() => handleSubmit(true)}
              size="large"
              className="secondary-btn"
            >
              {(isCreatingOfferDetails || isUpdatingOfferDetails) &&
              draft?.current ? (
                <LoadingOutlined className="spinner" />
              ) : (
                "Save as draft"
              )}
            </Button>
            <Button
              onClick={() => history.goBack()}
              size="large"
              className="secondary-btn"
            >
              Cancel
            </Button>
          </Row>
        )}
      </div>
    </>
  );
};

export default OfferDetails;
