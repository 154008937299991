import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "antd";

import Button from "@components/Button";
import Table from "@components/Table";
import { getColumns } from "./config";
import { paths, permissions } from "@router";
import { useFetchOffersQuery } from "@services";
import { useTypedSelector } from "@hooks";
import { handleStage, resetOffers } from "@store/offers";
import { Stage } from "@types";
import { checkPermission } from "@/utils";

const checkStatus = (pathname: string): "past" | "active" | "drafts" => {
  if (pathname.includes("drafts")) return "drafts";
  else if (pathname.includes("past")) return "past";
  else return "active";
};

const Offers = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { pathname } = useLocation<{ pathname: string }>();
  let status = checkStatus(pathname);
  const { data: offersData, isFetching: isLoading } = useFetchOffersQuery({
    company_id,
    page,
    status:
      status === "drafts"
        ? "DRAFTED"
        : status === "active"
        ? "PLACED"
        : "ACCEPTED&statuses=REJECTED",
  });
  const { data: offers, pagination } = offersData || {};

  const handleRowClick = (id: number, stage: Stage) => {
    dispatch(handleStage(stage));
    history.push(`/client/offers/offer/${id}`);
  };

  const columns = getColumns({ handleRowClick });
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="main-heading">{`${
            status === "drafts"
              ? "Drafts"
              : status === "active"
              ? "Offers"
              : "Past Offers"
          }`}</div>
        </Col>
      </Row>
      {status === "active" && checkPermission(permissions.CREATE_OFFER) ? (
        <Row className="mt-16 mb-20">
          <Col className="align-start" span={16}>
            <Button
              onClick={() => {
                dispatch(resetOffers());
                history.push(paths.client.offers.create);
              }}
              variant="upload_client"
            >
              Create Offer
            </Button>
          </Col>
        </Row>
      ) : (
        <div className="mb-20" />
      )}

      <Row>
        <Table
          isLoading={isLoading}
          data={offers}
          columns={columns}
          scroll={1300}
          pagination={true}
          count={pagination?.count}
          onChangePage={setPage}
          page={page}
        />
      </Row>
    </>
  );
};

export default Offers;
