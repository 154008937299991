import { createApi } from "@reduxjs/toolkit/query/react";

import { tomService } from "./restService";

export const internalPayrollDataApi = createApi({
  reducerPath: "internalPayrollDataApi",
  baseQuery: tomService({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/company`,
  }),
  tagTypes: ["InternalPayrollData", "InternalPayrollDataVersions"],
  endpoints: builder => ({
    updateInternalPayrollDataVersion: builder.mutation({
      query: ({ id, company_id }) => ({
        url: `/${company_id}/internal-payroll-version/${id}/set-active/`,
        method: "PATCH",
      }),
      invalidatesTags: ["InternalPayrollData", "InternalPayrollDataVersions"],
    }),
    fetchInternalPayrollDataVersions: builder.query({
      query: ({ company_id, page = 1 }) => ({
        url: `/${company_id}/internal-payroll-versions/?page=${page}`,
        method: "GET",
      }),
      providesTags: ["InternalPayrollDataVersions"],
    }),
    uploadInternalPayrollData: builder.mutation({
      query: ({ company_id, active, body }) => ({
        url: `/${company_id}/internal-payroll/upload/?active=${active}`,
        method: "POST",
        body,
        formData: true,
      }),
      invalidatesTags: ["InternalPayrollData", "InternalPayrollDataVersions"],
    }),
    downloadInternalPayrollData: builder.query({
      query: ({ company_id, version_id }) => ({
        url: `/${company_id}/internal-payroll/download/${
          version_id ? `?version_id=${version_id}` : ""
        }`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useDownloadInternalPayrollDataQuery,
  useFetchInternalPayrollDataVersionsQuery,
  useUpdateInternalPayrollDataVersionMutation,
  useUploadInternalPayrollDataMutation,
} = internalPayrollDataApi;
