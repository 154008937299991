import FixedCash from "./FixedCash";
import LongTermIncentives from "./LongTermIncentives";
import ShortTermIncentives from "./ShortTermIncentives";
import SignOnBonus from "./SignOnBonus";

export const panels = [
  {
    id: "1",
    header: "Fixed Cash / Guaranteed Cash",
    component: <FixedCash />,
  },
  {
    id: "2",
    header: "Short Term Incentives (STI)",
    component: <ShortTermIncentives />,
  },
  {
    id: "3",
    header: "Long Term Incentives (LTI)",
    component: <LongTermIncentives />,
  },
  {
    id: "4",
    header: "Sign On / One time payment",
    component: <SignOnBonus />,
  },
];
