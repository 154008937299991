import { Col, Input, Row } from "antd";
import { FC } from "react";

import { IModal } from "@types";
import Modal from "@components/Modal";
import { useTypedSelector } from "@hooks";
import { useFetchComparatorDataQuery } from "@services";

const ExternalComparatorData: FC<IModal> = ({ isVisible, setIsVisible }) => {
  const { offer } = useTypedSelector(state => state?.offers);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data } = useFetchComparatorDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    { skip: !offer?.id }
  );
  const { data: comparatorData } = data || {};
  const { external_comparator_data } = comparatorData || {};
  const { market_data_grade, market_data_grade_function } =
    external_comparator_data || {};

  return (
    <Modal
      mode="versions"
      footer={null}
      title="External Comparator Data"
      isVisible={isVisible}
      width={855}
      setIsVisible={setIsVisible}
    >
      <div className="offers__modal">
        <div className="offers__modal__row">
          <div className="offers__modal__title">{`Market Data - Grade ${offer?.offer_position_detail?.grade}`}</div>
          <Row justify="space-between">
            <Col span={7}>
              <label>P25</label>
              <Input
                readOnly
                value={market_data_grade?.p25 || ""}
                placeholder="P25 value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>P50</label>
              <Input
                readOnly
                value={market_data_grade?.p50 || ""}
                placeholder="P50 value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>P75</label>
              <Input
                readOnly
                value={market_data_grade?.p75 || ""}
                placeholder="P75 value"
                size="large"
              />
            </Col>
          </Row>
        </div>
        <div className="offers__modal__row">
          <div className="offers__modal__title">
            {`Market Data - Grade ${offer?.offer_position_detail?.grade} - Functional`}
          </div>
          <Row justify="space-between">
            <Col span={7}>
              <label>P25</label>
              <Input
                readOnly
                value={market_data_grade_function?.p25 || ""}
                placeholder="P25 value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>P50</label>
              <Input
                readOnly
                value={market_data_grade_function?.p50 || ""}
                placeholder="P50 value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>P75</label>
              <Input
                readOnly
                value={market_data_grade_function?.p75 || ""}
                placeholder="P75 value"
                size="large"
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default ExternalComparatorData;
