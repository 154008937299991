import { Col, message, Row } from "antd";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "@components/Button";
import Table from "@components/Table";
import {
  ErrorServices,
  useFetchLongTermIncentivesQuery,
  useDeleteLongTermIncentiveMutation,
  useUploadLongTermIncentivesMutation,
  longTermIPApi,
  useFetchLongTermIncentivesByPlanQuery,
} from "@services";
import { getColumns } from "./config";
import { ILongTermIncentive } from "@types";
import AddLongTermIncentive from "./AddIncentive";
import LongTermIncentiveVersions from "./Versions";
import Upload from "@components/Upload";
import { useTypedSelector } from "@hooks";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";

const LongTermIncentives = () => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { plan_id } = useParams<{ plan_id?: string }>();
  const { id: company_id } = company!;
  const dispatch = useDispatch();
  const longTermIncentive_id = useRef<any>(null);
  const [selectedLongTermIncentive, setSelectedLongTermIncentive] =
    useState<ILongTermIncentive | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVersionsModal, setIsVersionsModal] = useState(false);
  const [page, setPage] = useState(1);
  const [deleteLongTermIncentive, { isLoading: isDeleting }] =
    useDeleteLongTermIncentiveMutation();
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [uploadFile, setUploadFile] = useState({
    file: null,
    active: false,
  });
  const [uploadLongTermIncentives, { isLoading: isUploading }] =
    useUploadLongTermIncentivesMutation();
  const { data: incentivesData, isLoading } = useFetchLongTermIncentivesQuery(
    {
      company_id,
      page,
    },
    { skip: !!plan_id === true }
  );
  const { data: incentives, pagination: incentivesPagination } =
    incentivesData || {};
  const { data: incentivesByPlanData, isLoading: isFetchingPlanIncentives } =
    useFetchLongTermIncentivesByPlanQuery(
      {
        company_id,
        plan_id,
        page,
      },
      { skip: !!plan_id === false }
    );
  const { data: incentivesByPlan, pagination: incentivesByPlanPagination } =
    incentivesByPlanData || {};

  const data = plan_id ? incentivesByPlan : incentives;
  const pagination = plan_id
    ? incentivesByPlanPagination
    : incentivesPagination;

  const editLongTermIncentive = (incentive: ILongTermIncentive) => {
    setSelectedLongTermIncentive(incentive);
    setIsVisible(true);
  };

  const removeLongTermIncentive = async (id: number) => {
    try {
      longTermIncentive_id.current = id;
      await deleteLongTermIncentive({ company_id, id });
      message.success("Long term incentive deleted successfully!");
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const handleUpload = async () => {
    const { file, active }: { file: any; active: boolean } = uploadFile;
    setIsUploadModal(false);
    try {
      const formData = new FormData();
      formData.append("attachment", file!, file?.name!);
      const res = await uploadLongTermIncentives({
        company_id,
        active,
        body: formData,
      }).unwrap();
      message.success(res?.message);
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    }
  };

  const download = (id?: number) => {
    dispatch(
      longTermIPApi.endpoints.downloadLongTermIncentives.initiate(
        {
          company_id,
          version_id: id || null,
        },
        {
          subscribe: false,
          forceRefetch: true,
        }
      )
    );
  };

  return (
    <>
      {isUploadModal && (
        <Upload
          title="Upload Long Term Incentives"
          onSubmit={handleUpload}
          isVisible={isUploadModal}
          setIsVisible={setIsUploadModal}
          file={uploadFile}
          setFile={setUploadFile}
        />
      )}
      {isVisible && (
        <AddLongTermIncentive
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          selectedLongTermIncentive={selectedLongTermIncentive}
          setSelectedLongTermIncentive={setSelectedLongTermIncentive}
        />
      )}
      {isVersionsModal && (
        <LongTermIncentiveVersions
          isVisible={isVersionsModal}
          setIsVisible={setIsVersionsModal}
          download={download}
        />
      )}
      <Row>
        <Col span={24}>
          <div className="main-heading">Long Term Incentives</div>
        </Col>
      </Row>
      <Row className="mt-16 mb-20">
        <Col className="align-start" span={16}>
          {!plan_id ? (
            <>
              {checkPermission(permissions.CREATE_COMPANY_LTI) ||
              checkPermission(permissions.UPDATE_COMPANY_LTI) ? (
                <Button
                  onClick={() => setIsUploadModal(true)}
                  isLoading={isUploading}
                  variant="upload_client"
                >
                  Upload
                </Button>
              ) : null}

              <Button onClick={() => download()} variant="download_client">
                Download
              </Button>
            </>
          ) : null}
          <Button
            onClick={() => setIsVersionsModal(true)}
            variant="versions"
            icon={false}
          >
            Versions
          </Button>
          {checkPermission(permissions.CREATE_COMPANY_LTI) && (
            <Button
              onClick={() => setIsVisible(true)}
              variant="versions"
              icon={false}
            >
              Create Long Term Incentive
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Table
          scroll={1500}
          data={data}
          columns={getColumns({
            isDeleting,
            longTermIncentive_id,
            removeLongTermIncentive,
            editLongTermIncentive,
          })}
          isLoading={isLoading || isFetchingPlanIncentives}
          pagination={true}
          count={pagination?.count}
          onChangePage={setPage}
          page={page}
        />
      </Row>
    </>
  );
};

export default LongTermIncentives;
