import { Col, Input, Row } from "antd";

import { useTypedSelector } from "@hooks";

const JobInfo = () => {
  const { offer } = useTypedSelector(state => state?.offers);
  const { offer_candidate_detail, offer_position_detail } = offer || {};

  return (
    <>
      <Row className="offers__form__job-info">
        <Col className="offers__form__job-info__headerCol" span={12}>
          <div className="sub-heading">Candidate's Details</div>
        </Col>
        <Col className="offers__form__job-info__headerCol" span={12}>
          <div className="sub-heading">Offer Details</div>
        </Col>
      </Row>
      <Row className="offers__form__job-info">
        <Col className="offers__form__job-info__col" span={12}>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={7}>
              <label>Name</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                readOnly
                value={offer_candidate_detail?.candidate_name || ""}
                placeholder="Enter candidate name here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={7}>
              <label>Country</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                readOnly
                value={offer_candidate_detail?.country?.name || ""}
                placeholder="Enter country here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={7}>
              <label>Location</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                readOnly
                value={offer_candidate_detail?.location || ""}
                placeholder="Enter location here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={7}>
              <label>Company</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                readOnly
                value={offer_candidate_detail?.company_name || ""}
                placeholder="Enter company here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={7}>
              <label>Title</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                value={offer_candidate_detail?.current_title || ""}
                readOnly
                placeholder="Enter business title here..."
                size="large"
              />
            </Col>
          </Row>
        </Col>
        <Col className="offers__form__job-info__col" span={12}>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={9}>
              <label>Job Title</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                readOnly
                value={offer_position_detail?.job_title || ""}
                placeholder="Enter date name here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={9}>
              <label>BU</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                value={offer_position_detail?.business_unit?.name || ""}
                readOnly
                placeholder="Enter BU here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={9}>
              <label>Grade</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                value={offer_position_detail?.grade || ""}
                readOnly
                placeholder="Enter grade here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={9}>
              <label>Region</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                value={offer_position_detail?.region?.name || ""}
                readOnly
                placeholder="Enter region here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={9}>
              <label>Country</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                value={offer_position_detail?.country?.name || ""}
                readOnly
                placeholder="Enter country here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={9}>
              <label>Type</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                value={offer_position_detail?.grade_type || ""}
                readOnly
                placeholder="Enter type here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={9}>
              <label>Job Function</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                value={offer_position_detail?.job_function?.name || ""}
                readOnly
                placeholder="Enter job function here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={9}>
              <label>Job Sub Function</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                readOnly
                value={offer_position_detail?.sub_job_function?.name || ""}
                placeholder="Enter job sub function here..."
                size="large"
              />
            </Col>
          </Row>
          <Row>
            <Col className="offers__form__job-info__col--nested" span={9}>
              <label>City</label>
            </Col>
            <Col className="offers__form__job-info__col--nested" span={13}>
              <Input
                readOnly
                value={offer_position_detail?.city || ""}
                placeholder="Enter job sub function here..."
                size="large"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default JobInfo;
