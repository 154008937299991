import { Button, Col, Input, message, Row, Select } from "antd";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

import { handleCandidateDetails, handleStage } from "@store/offers";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useTypedSelector } from "@hooks";
import {
  ErrorServices,
  useCreateCandidateDetailsMutation,
  useFetchAllJobFunctionsQuery,
  useFetchAllSectorsQuery,
  useFetchCompanyCountriesQuery,
  useFetchCurrenciesQuery,
} from "@services";
import {
  ICountry,
  ICurrency,
  IIndustry,
  IJobFunctionItem,
  ISector,
  ISubIndustry,
  ISubJobFunctionItem,
} from "@types";

const { Option } = Select;

const CandidateDetails = ({ offerModeller }: { offerModeller?: boolean }) => {
  const dispatch = useDispatch();
  const draft = useRef<boolean>(false);
  const { offer } = useTypedSelector(state => state?.offers);
  const readOnly =
    offerModeller || (offer && offer?.status !== "DRAFTED") ? true : false;
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const is_create = window.location.pathname.includes("/create");
  const [subJobFunctions, setSubJobFunctions] = useState<ISubJobFunctionItem[]>(
    []
  );
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [subIndustries, setSubIndustries] = useState<ISubIndustry[]>([]);
  const { candidateDetails } = useTypedSelector(state => state.offers);

  const { data: sectorsData, isLoading: isLoadingSectors } =
    useFetchAllSectorsQuery(null);
  const { data: sectors } = sectorsData || {};
  const { countries } = useTypedSelector(state => state.countries);
  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions } =
    useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};
  const { data: currenciesData, isLoading: isFetchingCurrencies } =
    useFetchCurrenciesQuery(null);
  const { data: currencies } = currenciesData || {};
  const [createCandidateDetails, { isLoading: isCreating }] =
    useCreateCandidateDetailsMutation();
  const {
    candidate_name,
    company_name,
    current_title,
    current_grade,
    business_unit,
    region,
    location,
    relevant_experience,
    total_experience,
    is_critical_skills,
    critical_skills,
    is_referral,
    referral_employees,
    country_id,
    currency_id,
    current_job_function,
    current_sub_job_function_id,
    sector,
    industry,
    sub_industry_id,
  } = candidateDetails;
  const disabled =
    !candidate_name ||
    !company_name ||
    !current_title ||
    !location ||
    !total_experience ||
    !country_id ||
    !currency_id ||
    !current_job_function ||
    !industry;

  useEffect(() => {
    if (!is_create) {
      const jobFunction = jobFunctions?.find(
        (func: Partial<IJobFunctionItem>) =>
          func.id === current_job_function?.id
      );
      const selected_sector = sectors?.find(
        (x: Partial<ISector>) => x.id === sector?.id
      );
      setSubJobFunctions(jobFunction?.job_sub_functions);
      setIndustries(selected_sector?.industries);
      setSubIndustries(
        selected_sector?.industries?.find(
          (x: Partial<IIndustry>) => x?.id === industry?.id
        )?.sub_industries
      );
    }
  }, [
    current_job_function?.id,
    industry?.id,
    is_create,
    jobFunctions,
    sector?.id,
    sectors,
  ]);

  const handleChange = (event: any) => {
    if (readOnly) return;
    dispatch(
      handleCandidateDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleSubmit = async (is_draft: boolean) => {
    try {
      draft.current = is_draft;
      const body: any = {
        ...candidateDetails,
        current_job_function_id: current_job_function?.id,
        industry_id: industry?.id,
        sector_id: sector?.id,
        critical_skills:
          !is_critical_skills || !critical_skills || !critical_skills?.length
            ? null
            : critical_skills,
        referral_employees: is_referral ? referral_employees : null,
        is_draft,
      };
      delete body["current_job_function"];
      delete body["industry"];
      delete body["sector"];
      await createCandidateDetails({
        company_id,
        id: offer?.id,
        body,
      }).unwrap();
      message.success("Candidate details saved successfully");
    } catch (err) {
      console.log(err);
      ErrorServices(err);
    }
  };

  return (
    <>
      <Row className="offers__form__header">
        <div className="sub-heading">Candidate Details</div>
      </Row>
      <div className="offers__form__fields">
        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <label>Candidate Name</label>
            <Input
              readOnly={readOnly}
              onChange={handleChange}
              value={candidate_name || ""}
              placeholder="Enter candidate name here..."
              size="large"
              name="candidate_name"
            />
          </Col>
          <Col span={7}>
            <label>Current Title</label>
            <Input
              readOnly={readOnly}
              name="current_title"
              onChange={handleChange}
              value={current_title || ""}
              placeholder="Enter current title here..."
              size="large"
            />
          </Col>
          <Col span={7}>
            <label>Candidate Company Name</label>
            <Input
              readOnly={readOnly}
              onChange={handleChange}
              name="company_name"
              value={company_name || ""}
              size="large"
              placeholder="Enter candidate company name here..."
            />
          </Col>
        </Row>

        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <label>Region</label>
            <Input
              readOnly={readOnly}
              onChange={handleChange}
              name="region"
              value={region || ""}
              size="large"
              placeholder="Enter region here..."
            />
          </Col>
          <Col span={7}>
            <label>Country</label>
            <Select
              value={country_id || undefined}
              onChange={value =>
                !readOnly &&
                handleChange({ target: { name: "country_id", value } })
              }
              size="large"
              showArrow
              placeholder="Select country from here..."
              showSearch={true}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries?.map((country: ICountry) => (
                <Option key={country?.id} value={country?.id}>
                  {country?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <label>Location</label>
            <Input
              readOnly={readOnly}
              name="location"
              size="large"
              placeholder="Enter location here..."
              onChange={handleChange}
              value={location || ""}
            />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <label>Current Grade</label>
            <Input
              readOnly={readOnly}
              size="large"
              placeholder="Enter grade here..."
              name="current_grade"
              onChange={handleChange}
              value={current_grade || ""}
            />
          </Col>

          <Col span={7}>
            <label>Business Unit</label>
            <Input
              readOnly={readOnly}
              name="business_unit"
              size="large"
              placeholder="Enter business unit here..."
              onChange={handleChange}
              value={business_unit || ""}
            />
          </Col>
          <Col span={7}>
            <label>Current Job Function</label>
            <Select
              loading={isLoadingJobFunctions}
              size="large"
              showArrow
              placeholder="Select job function from here..."
              showSearch={false}
              onChange={(serialized_jobFunction: string) => {
                if (readOnly) return;
                const jobFunction = JSON.parse(serialized_jobFunction);
                handleChange({
                  target: {
                    name: "current_job_function",
                    value: { id: jobFunction?.id, name: jobFunction?.name },
                  },
                });
                dispatch(
                  handleCandidateDetails({
                    current_sub_job_function_id: null,
                  })
                );
                setSubJobFunctions(jobFunction?.job_sub_functions);
              }}
              value={current_job_function?.name || undefined}
            >
              {jobFunctions?.map((jobFunction: Partial<IJobFunctionItem>) => (
                <Option
                  key={jobFunction?.id}
                  value={JSON.stringify(jobFunction)}
                >
                  {jobFunction?.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <label>Current Sub-Job Function</label>
            <Select
              disabled={!current_job_function?.id}
              size="large"
              showArrow
              placeholder="Select sub-job function from here..."
              showSearch={false}
              onChange={value =>
                !readOnly &&
                handleChange({
                  target: { name: "current_sub_job_function_id", value },
                })
              }
              value={current_sub_job_function_id || undefined}
            >
              {subJobFunctions?.map(({ id, name }: ISubJobFunctionItem) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={7}>
            <label>Current Currency</label>
            <Select
              value={currency_id || undefined}
              loading={isFetchingCurrencies}
              onChange={value =>
                !readOnly &&
                handleChange({
                  target: { name: "currency_id", value },
                })
              }
              showArrow={true}
              showSearch={true}
              size="large"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select currency from here..."
            >
              {currencies?.map(({ id, name, code }: ICurrency) => (
                <Option key={id} value={id}>
                  {`${name} (${code})`}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <label>Sector</label>
            <Select
              loading={isLoadingSectors}
              size="large"
              showArrow
              placeholder="Select sector from here..."
              showSearch={false}
              onChange={(serialized_sector: string) => {
                if (readOnly) return;
                const sector = JSON.parse(serialized_sector);
                handleChange({
                  target: {
                    name: "sector",
                    value: { id: sector?.id, name: sector?.name },
                  },
                });
                dispatch(
                  handleCandidateDetails({
                    industry: null,
                    sub_industry_id: null,
                  })
                );
                setIndustries(sector?.industries);
              }}
              value={sector?.name || undefined}
            >
              {sectors?.map((sector: Partial<ISector>) => (
                <Option key={sector?.id} value={JSON.stringify(sector)}>
                  {sector?.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <label>Industry</label>
            <Select
              disabled={!sector?.id}
              size="large"
              showArrow
              placeholder="Select industry from here..."
              showSearch={false}
              onChange={(serialized_industry: string) => {
                if (readOnly) return;
                const industry = JSON.parse(serialized_industry);
                handleChange({
                  target: {
                    name: "industry",
                    value: { id: industry?.id, name: industry?.name },
                  },
                });
                dispatch(
                  handleCandidateDetails({
                    sub_industry_id: null,
                  })
                );
                setSubIndustries(industry?.sub_industries);
              }}
              value={industry?.name || undefined}
            >
              {industries?.map((industry: Partial<IIndustry>) => (
                <Option key={industry?.id} value={JSON.stringify(industry)}>
                  {industry?.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={7}>
            <label>Sub-Industry</label>
            <Select
              disabled={!industry?.id}
              size="large"
              showArrow
              placeholder="Select sub-industry from here..."
              showSearch={false}
              value={sub_industry_id || undefined}
              onChange={value =>
                !readOnly &&
                handleChange({
                  target: { name: "sub_industry_id", value },
                })
              }
            >
              {subIndustries?.map((subIndustry: Partial<IIndustry>) => (
                <Option key={subIndustry?.id} value={subIndustry?.id!}>
                  {subIndustry?.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={7}>
            <label>Total Experience</label>
            <Input
              readOnly={readOnly}
              type="number"
              onChange={handleChange}
              name="total_experience"
              size="large"
              placeholder="Enter total experience here..."
              value={total_experience || ""}
            />
          </Col>
        </Row>
        <Row justify="space-between" className="mb-32">
          <Col span={7}>
            <label>Relevant Experience</label>
            <Input
              readOnly={readOnly}
              type="number"
              onChange={handleChange}
              name="relevant_experience"
              size="large"
              placeholder="Enter relevant experience here..."
              value={relevant_experience || ""}
            />
          </Col>
          <Col span={7}>
            <label>Critical Skills</label>
            <Input
              readOnly={readOnly}
              onChange={handleChange}
              name="critical_skills"
              size="large"
              placeholder="Enter critical skills here..."
              value={critical_skills || ""}
              disabled={!is_critical_skills}
            />

            <br />
            <Checkbox
              checked={is_critical_skills}
              onChange={event =>
                handleChange({
                  target: {
                    name: "is_critical_skills",
                    value: event.target.checked,
                  },
                })
              }
              className="offers__form__fields__checkbox mt-24"
            >
              Critical Skills
            </Checkbox>
          </Col>

          <Col span={7}>
            <label>Referral</label>
            <Input
              readOnly={readOnly}
              disabled={!is_referral}
              size="large"
              placeholder="Enter referral employee name here..."
              onChange={handleChange}
              name="referral_employees"
              value={referral_employees || ""}
            />
            <br />
            <Checkbox
              checked={is_referral}
              onChange={event =>
                handleChange({
                  target: {
                    name: "is_referral",
                    value: event.target.checked,
                  },
                })
              }
              className="offers__form__fields__checkbox mt-24"
            >
              Is Referral
            </Checkbox>
          </Col>
        </Row>
        {offerModeller ? null : (
          <Row className="offers__form__fields__btns">
            <Button
              disabled={disabled}
              onClick={() => handleSubmit(false)}
              size="large"
              type="primary"
            >
              {isCreating && !draft?.current ? (
                <LoadingOutlined className="spinner" />
              ) : (
                "Next"
              )}
            </Button>
            <Button
              disabled={disabled}
              onClick={() => handleSubmit(true)}
              size="large"
              className="secondary-btn"
            >
              {isCreating && draft?.current ? (
                <LoadingOutlined className="spinner" />
              ) : (
                "Save as draft"
              )}
            </Button>
            <Button
              onClick={() => {
                dispatch(handleStage("POSITION_DETAILS"));
              }}
              size="large"
              className="secondary-btn"
            >
              Cancel
            </Button>
          </Row>
        )}
      </div>
    </>
  );
};

export default CandidateDetails;
