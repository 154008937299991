import { Switch, TableColumnsType } from "antd";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

import { ICashAllowance, ICountry, IVersion } from "@types";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";
import { dateFormat } from "@/constants";

export const getColumns = ({
  removeCashAllowance,
  cashAllowance_id,
  isDeleting,
  editCashAllowance,
}: {
  isDeleting?: boolean;
  removeCashAllowance: (id: number) => Promise<void>;
  cashAllowance_id?: React.MutableRefObject<any>;
  editCashAllowance: (cashAllowance: ICashAllowance) => void;
}): TableColumnsType<any> => [
  {
    title: "country",
    dataIndex: "country",
    key: "country",
    width: 200,
    render: (country: ICountry) => <div>{country?.name}</div>,
  },
  {
    title: "city",
    dataIndex: "city",
    key: "city",
    width: 200,
  },
  {
    title: "grade",
    key: "grade",
    width: 150,
    render: (cashAllowance: ICashAllowance) =>
      cashAllowance?.is_all_grade ? "All" : cashAllowance?.grade,
  },
  {
    title: "allowance name",
    key: "allowance_name",
    dataIndex: "allowance_name",
    width: 250,
    render: (allowance_name: string) => allowance_name || "",
  },
  {
    title: "amount/percentage",
    dataIndex: "is_percentage",
    key: "is_percentage",
    width: 250,
    render: (is_percentage: boolean) =>
      is_percentage ? "Percentage" : "Amount",
  },
  {
    title: "basic",
    dataIndex: "is_basic_pay",
    key: "is_basic_pay",
    width: 150,
    render: (basic: boolean) => (basic ? "Yes" : "No"),
  },
  {
    title: "value",
    key: "value",
    width: 150,
    render: (cashAllowance: ICashAllowance) =>
      `${cashAllowance?.value}${cashAllowance?.is_percentage ? "%" : ""}`,
  },
  ...(!checkPermission([
    permissions.UPDATE_CASH_ALLOWANCE,
    permissions.DELETE_CASH_ALLOWANCE,
  ])
    ? []
    : ([
        {
          title: "Actions",
          key: "actions",
          width: 160,
          fixed: "right",
          render: (cashAllowance: ICashAllowance) => (
            <>
              {checkPermission(permissions.UPDATE_CASH_ALLOWANCE) && (
                <div
                  onClick={() => editCashAllowance(cashAllowance)}
                  className="table__action__btn table__action__btn--client"
                >
                  Edit
                </div>
              )}

              {checkPermission(permissions.DELETE_CASH_ALLOWANCE) && (
                <div
                  onClick={() => removeCashAllowance(cashAllowance?.id!)}
                  className="table__action__btn table__action__btn--delete"
                >
                  {isDeleting &&
                  cashAllowance?.id === cashAllowance_id?.current ? (
                    <LoadingOutlined color="red" className="spinner" />
                  ) : (
                    "Delete"
                  )}
                </div>
              )}
            </>
          ),
        },
      ] as any)),
];

export const getVersionsColumns = ({
  handleActive,
  versionId,
  download,
}: {
  handleActive: (id: number) => Promise<void>;
  versionId: number | null;
  download: (id?: number | undefined) => void;
}): TableColumnsType<any> => [
  {
    title: "created at",
    dataIndex: "created_at",
    key: "created_at",
    width: "35%",
    render: (date: string) => <div>{moment(date).format(dateFormat)}</div>,
  },
  ...(!checkPermission(permissions.CREATE_CASH_ALLOWANCE)
    ? []
    : ([
        {
          title: "Active",
          key: "active",
          width: "15%",
          align: "center",
          render: (version: any) => (
            <Switch
              onChange={checked => {
                if (!checked) return;
                handleActive(version?.id);
              }}
              checked={
                versionId === version?.id ||
                (version?.is_active === "TRUE" && versionId === version?.id)
              }
              defaultChecked={version?.is_active === "TRUE"}
            />
          ),
        },
      ] as any)),
  {
    title: "action",
    key: "action",
    width: "15%",
    render: (version: IVersion) => (
      <div
        onClick={() => download(version?.id)}
        className="table__action__btn table__action__btn--client"
      >
        Download
      </div>
    ),
  },
];
