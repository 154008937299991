import { useDispatch } from "react-redux";
import { Row, Col, Input } from "antd";
import _ from "lodash";

import { ReactComponent as PlusIcon } from "@assets/images/plus-with-bg.svg";
import { useTypedSelector } from "@hooks";
import { handleOfferModeller } from "@store/offers";
import { getCurrencyTotal, round } from "@utils";

const LongTermIncentives = () => {
  const dispatch = useDispatch();

  const {
    offerModeller: { offer_lti, offer_sti, offer_fixed_cash },
    currencyRate,
    currencyName,
    offer,
  } = useTypedSelector(state => state.offers);
  const readOnly = offer && offer?.status !== "DRAFTED" ? true : false;
  const currencyCode = currencyName?.split("(")[1]?.replace(")", "") || "";

  const labelColSpan = currencyCode ? 10 : 12;
  const currencyColSpan = currencyCode ? 7 : 12;
  const { current_currency_total, proposed_currency_total } = getCurrencyTotal(
    offer_lti,
    currencyRate,
    []
  );

  const handleChange = (event: any) => {
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          [event.target.name]: +event.target.value || null,
        },
      })
    );
  };

  const addMoreLocally = (is_current: boolean) => {
    const newCurrent = {
      is_current,
      value: null,
      name: null,
      id: +_.uniqueId(),
      readonly: true,
    };
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          other_ltis: [...offer_lti.other_ltis, newCurrent],
        },
      })
    );
  };

  const handleChangeNewField = (
    id: number,
    value: number | string,
    type: "name" | "value"
  ) => {
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          other_ltis: offer_lti?.other_ltis?.map(lti =>
            lti?.id === id ? { ...lti, [type]: value } : lti
          ) as any,
        },
      })
    );
  };

  const removeField = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          other_ltis: offer_lti?.other_ltis?.map(lti =>
            lti?.id === id ? { ...lti, readonly: false } : lti
          ) as any,
        },
      })
    );
  };

  const removeBonus = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_lti",
        data: {
          other_ltis: offer_lti?.other_ltis?.filter(
            lti => lti?.id !== id
          ) as any,
        },
      })
    );
  };

  return (
    <Row className="salary-offer">
      <Col className="salary-offer__parent-col" span={12}>
        <Row className="salary-offer__header-row">
          <Col span={12}>
            <div className="sub-heading">Current</div>
          </Col>
        </Row>
        <Row className="salary-offer__content-row">
          {/* Current-Cols */}
          <Col className="salary-offer__content-row__col" span={labelColSpan}>
            <div className="salary-offer__content-row__field-upper"></div>

            <div className="salary-offer__content-row__field">
              <label>Unvested Equity</label>
            </div>
            <div className="salary-offer__content-row__field">
              <label>Annual Grant</label>
            </div>

            {offer_lti?.other_ltis
              ?.filter(({ is_current }) => is_current)
              ?.map(({ name, id, readonly }) => (
                <div key={id} className="salary-offer__content-row__field">
                  {name?.length && !readonly! ? (
                    <label>{name}</label>
                  ) : (
                    <Input
                      readOnly={readOnly}
                      autoFocus
                      onChange={e =>
                        handleChangeNewField(id!, e?.target?.value, "name")
                      }
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          removeField(id!);
                        }
                      }}
                      value={name || ""}
                      placeholder="Value here..."
                      size="large"
                    />
                  )}
                  {!readOnly && (
                    <div
                      className="salary-offer__content-row__field__remove"
                      onClick={() => removeBonus(id!)}
                    >
                      <PlusIcon fill="#000" />
                    </div>
                  )}
                </div>
              ))}

            {!readOnly ? (
              <div
                onClick={() => addMoreLocally(true)}
                className="salary-offer__content-row__field--add-more"
              >
                <PlusIcon fill="#4FAA89" />
                <span>Add more</span>
              </div>
            ) : (
              <div className="salary-offer__content-row__field--add-more" />
            )}

            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <label>Total LTIs</label>
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total" />
          </Col>
          <Col
            className="salary-offer__content-row__col"
            span={currencyColSpan}
          >
            <div className="salary-offer__content-row__field-upper">
              {offer?.offer_position_detail?.currency?.code}
            </div>
            <div className="salary-offer__content-row__field">
              <Input
                readOnly={readOnly}
                type="number"
                name="current_unvested_equity"
                onChange={handleChange}
                value={offer_lti?.current_unvested_equity || ""}
                placeholder="Value here..."
                size="large"
              />
            </div>
            <div className="salary-offer__content-row__field">
              <Input
                readOnly={readOnly}
                type="number"
                name="current_annual_grant"
                value={offer_lti?.current_annual_grant || ""}
                onChange={handleChange}
                placeholder="Value here..."
                size="large"
              />
            </div>

            {offer_lti?.other_ltis
              ?.filter(({ is_current }) => is_current)
              ?.map(({ name, value, id }) => (
                <div key={id} className="salary-offer__content-row__field">
                  <Input
                    readOnly={readOnly}
                    name={name!}
                    type="number"
                    value={value || ""}
                    onChange={e =>
                      handleChangeNewField(id!, +e.target.value, "value")
                    }
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              ))}

            <div className="salary-offer__content-row__field--add-more" />
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <Input
                readOnly
                value={offer_lti?.current_total_lti || ""}
                name="current_total_lti"
                placeholder="Value here..."
                size="large"
              />
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total" />
          </Col>
          {currencyCode ? (
            <Col className="salary-offer__content-row__col" span={7}>
              <div className="salary-offer__content-row__field-upper">
                {currencyCode}
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  value={
                    round(
                      offer_lti?.current_unvested_equity! * currencyRate!
                    ) || ""
                  }
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  value={
                    round(offer_lti?.current_annual_grant! * currencyRate!) ||
                    ""
                  }
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>

              {offer_lti?.other_ltis
                ?.filter(({ is_current }) => is_current)
                ?.map(({ id, value }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    <Input
                      value={round(value! * currencyRate!) || ""}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                    />
                  </div>
                ))}

              <div className="salary-offer__content-row__field--add-more" />
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  value={current_currency_total || ""}
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total" />
            </Col>
          ) : null}
        </Row>
      </Col>
      <Col className="salary-offer__parent-col" span={12}>
        <Row className="salary-offer__header-row">
          <Col span={12}>
            <div className="sub-heading">Proposed</div>
          </Col>
        </Row>
        <Row className="salary-offer__content-row">
          {/* Proposed-Cols */}

          <Col className="salary-offer__content-row__col" span={labelColSpan}>
            <div className="salary-offer__content-row__field-upper"></div>
            <div className="salary-offer__content-row__field">
              <label>New Hire Equity</label>
            </div>

            <div className="salary-offer__content-row__field">
              <label>Annual Grant</label>
            </div>

            {offer_lti?.other_ltis
              ?.filter(({ is_current }) => !is_current)
              ?.map(({ name, id, readonly }) => (
                <div key={id} className="salary-offer__content-row__field">
                  {name?.length && !readonly! ? (
                    <label>{name}</label>
                  ) : (
                    <Input
                      readOnly={readOnly}
                      autoFocus
                      onChange={e =>
                        handleChangeNewField(id!, e?.target?.value, "name")
                      }
                      onKeyDown={e => {
                        if (e.key === "Enter") {
                          removeField(id!);
                        }
                      }}
                      value={name || ""}
                      placeholder="Value here..."
                      size="large"
                    />
                  )}
                  {!readOnly && (
                    <div
                      className="salary-offer__content-row__field__remove"
                      onClick={() => removeBonus(id!)}
                    >
                      <PlusIcon fill="#000" />
                    </div>
                  )}
                </div>
              ))}

            {!readOnly ? (
              <div
                onClick={() => addMoreLocally(false)}
                className="salary-offer__content-row__field--add-more"
              >
                <PlusIcon fill="#4FAA89" />
                <span>Add more</span>
              </div>
            ) : (
              <div className="salary-offer__content-row__field--add-more" />
            )}

            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <label>Total LTIs</label>
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <label>Difference</label>
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <label>Total Direct Comp</label>
            </div>
          </Col>
          <Col
            className="salary-offer__content-row__col"
            span={currencyColSpan}
          >
            <div className="salary-offer__content-row__field-upper">
              {offer?.offer_position_detail?.currency?.code}
            </div>
            <div className="salary-offer__content-row__field">
              <Input
                readOnly={readOnly}
                type="number"
                name="proposed_new_hire_equity"
                onChange={handleChange}
                value={offer_lti?.proposed_new_hire_equity || ""}
                placeholder="Value here..."
                size="large"
              />
            </div>
            <div className="salary-offer__content-row__field">
              <Input
                readOnly={readOnly}
                type="number"
                name="proposed_annual_grant"
                onChange={handleChange}
                value={offer_lti?.proposed_annual_grant || ""}
                placeholder="Value here..."
                size="large"
              />
            </div>

            {offer_lti?.other_ltis
              ?.filter(({ is_current }) => !is_current)
              ?.map(({ name, value, id, unit }) => (
                <div key={id} className="salary-offer__content-row__field">
                  <div
                    className={`${
                      unit
                        ? "salary-offer__content-row__field__inner"
                        : "salary-offer__content-row__field__no-unit"
                    }`}
                  >
                    <Input
                      readOnly={readOnly}
                      name={name!}
                      type="number"
                      value={value || ""}
                      onChange={e =>
                        handleChangeNewField(id!, +e.target.value, "value")
                      }
                      placeholder="Value here..."
                      size="large"
                    />
                    {unit ? (
                      <div className="salary-offer__content-row__field__unit">
                        units
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}

            <div className="salary-offer__content-row__field--add-more" />
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <Input
                readOnly
                name="proposed_total_lti"
                placeholder="Value here..."
                value={offer_lti?.proposed_total_lti || ""}
                size="large"
              />
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <Input
                readOnly
                value={
                  offer_lti?.proposed_total_lti && offer_lti?.current_total_lti
                    ? `${round(
                        (offer_lti?.proposed_total_lti /
                          offer_lti?.current_total_lti -
                          1) *
                          100
                      )}%` || ""
                    : ""
                }
                placeholder="Value here..."
                size="large"
              />
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <Input
                readOnly
                name="total_direct_comp"
                placeholder="Value here..."
                value={
                  offer_fixed_cash?.proposed_total &&
                  offer_sti?.proposed_total_bonus &&
                  offer_lti?.proposed_total_lti
                    ? offer_fixed_cash?.proposed_total +
                      offer_sti?.proposed_total_bonus +
                      offer_lti?.proposed_total_lti
                    : ""
                }
                size="large"
              />
            </div>
          </Col>
          {currencyCode ? (
            <Col className="salary-offer__content-row__col" span={7}>
              <div className="salary-offer__content-row__field-upper">
                {currencyCode}
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  value={
                    round(
                      offer_lti?.proposed_new_hire_equity! * currencyRate!
                    ) || ""
                  }
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  value={
                    round(offer_lti?.proposed_annual_grant! * currencyRate!) ||
                    ""
                  }
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              {offer_lti?.other_ltis
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ id, value }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    <Input
                      value={round(value! * currencyRate!) || ""}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                    />
                  </div>
                ))}

              <div className="salary-offer__content-row__field--add-more" />
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  value={proposed_currency_total || ""}
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total" />
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

export default LongTermIncentives;
