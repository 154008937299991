import { useEffect, useRef, useState } from "react";
import { Col, Dropdown, Menu, message, Row, TableColumnsType } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { ReactComponent as MenuIcon } from "@assets/images/vertical-dots.svg";

import Table from "@components/Table";
import Button from "@components/Button";
import { checkPermission } from "@utils";
import { permissions } from "@router";
import {
  ErrorServices,
  jobFunctionsApi,
  useDeleteJobFunctionMutation,
  useFetchJobFunctionsQuery,
  useUploadJobFunctionsMutation,
} from "@services";
import AddJobFunction from "./AddJobFunction";
import EditJobFunction from "./EditJobFunction";

type JobSubFunctionType = {
  id: number;
  name: string;
};

type JobFunctionType = {
  id: number;
  name: string;
  job_sub_functions: Array<JobSubFunctionType>;
};

const JobFunction = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  let jf_id = useRef<any>(null);

  const [isAddJFVisible, setIsAddJFVisible] = useState(false);
  const [isEditJFVisible, setIsEditJFVisible] = useState(false);
  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions, refetch: refetchJobFunctions } =
    useFetchJobFunctionsQuery({ page });
  const { data: jobFunctions, pagination } = jobFunctionsData || {};
  const [deleteJobFunction] = useDeleteJobFunctionMutation();
  const [jfItem, setJfItem] = useState({});

  const [uploadJobFunction, { isLoading: isUploading }] =
    useUploadJobFunctionsMutation();

  useEffect(() => {
    refetchJobFunctions();
  }, []);

  // const [downloadJobFunction, { isLoading: isDownloading }] =
  //   useDownloadJobFunctionsMutation();

  const inputRef = useRef<any>(null);

  const handleActionDropdown = ({
    item,
    key,
    domEvent,
  }: {
    key: string;
    domEvent:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>;
    item: JobFunctionType;
  }) => {
    domEvent.stopPropagation();
    if (key === "2") {
      removeJobFunction(item?.id.toString());
    } else {
      onEditJf(item);
    }
  };

  const columns: TableColumnsType<JobFunctionType> = [
    {
      title: "Job Function",
      key: "name",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Job Sub-Function",
      key: "job_sub_functions",
      width: "55%",
      render: ({ job_sub_functions }) => {
        if (Array.isArray(job_sub_functions) && job_sub_functions.length) {
          return job_sub_functions
            .map((val: JobSubFunctionType) => {
              return val.name;
            })
            .join(", ");
        }
        return null;
      },
    },
    ...((!checkPermission([
      permissions.UPDATE_JOB_SUB_FUNCTION,
      permissions.DELETE_JOB_SUB_FUNCTION,
    ])
      ? []
      : [
          {
            title: "Actions",
            key: "action",
            fixed: "right",
            width: "10%",
            render: (item: JobFunctionType) => {
              const menu = (
                <Menu
                  onClick={({ key, domEvent }) =>
                    handleActionDropdown({ item, key, domEvent })
                  }
                  tabIndex={1}
                >
                  {checkPermission(permissions.UPDATE_JOB_SUB_FUNCTION) && (
                    <Menu.Item key="1">Edit</Menu.Item>
                  )}
                  {checkPermission(permissions.DELETE_JOB_SUB_FUNCTION) && (
                    <Menu.Item key="2" danger>
                      Delete
                    </Menu.Item>
                  )}
                </Menu>
              );
              return (
                <div className="table__action__menu">
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <MenuIcon
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    />
                  </Dropdown>
                </div>
              );
            },
          },
        ]) as any),
  ];

  const onRowClick = (data: any) => {
    history.push(`/job-functions/${data.id}`, { id: data.id });
  };

  const onEditJf = async (item: JobFunctionType) => {
    try {
      setJfItem(item);
      setIsEditJFVisible(true);
    } catch (error) {
      ErrorServices(error);
    }
  };

  const removeJobFunction = async (id: string) => {
    try {
      jf_id.current = id;
      await deleteJobFunction(id).unwrap();
    } catch (error) {
      ErrorServices(error);
    }
  };

  const uploadFile = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event?.target?.files[0];
    try {
      const formData = new FormData();
      formData.append("attachment", file, file.name);
      await uploadJobFunction(formData).unwrap();

      message.success("CSV Data Uploaded Successfully");
    } catch (error) {
      ErrorServices(error);
    }
  };

  const downloadFile = () => {
    dispatch(
      jobFunctionsApi.endpoints.downloadJobFunctions.initiate(null, {
        subscribe: false,
        forceRefetch: true,
      })
    );
  };

  return (
    <>
      <AddJobFunction
        setIsVisible={setIsAddJFVisible}
        isVisible={isAddJFVisible}
      />
      <EditJobFunction
        setIsVisible={setIsEditJFVisible}
        isVisible={isEditJFVisible}
        jfItem={jfItem}
      />
      <Row>
        <Col span={24}>
          <div className="main-heading">Job Functions & Sub-Job Functions</div>
        </Col>
      </Row>
      <Row className="mt-16 mb-20">
        <Col className="align-start" span={16}>
          <input
            id="myInput"
            type="file"
            ref={inputRef}
            hidden={true}
            onChange={e => uploadFile(e)}
          />
          <Button variant="upload" onClick={() => inputRef?.current?.click()}>
            {isUploading ? (
              <LoadingOutlined color="white" className="spinner" />
            ) : (
              "Upload Job Functions"
            )}
          </Button>
          <Button variant="download" onClick={downloadFile}>
            Download Job Functions
          </Button>
        </Col>
        <Col className="align-end" span={8}>
          {checkPermission(permissions.CREATE_JOB_SUB_FUNCTION) && (
            <Button variant="add" onClick={() => setIsAddJFVisible(true)}>
              Add New Job
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Table
          data={jobFunctions}
          columns={columns}
          onRowClick={onRowClick}
          isLoading={isLoadingJobFunctions}
          pagination={true}
          count={pagination?.count}
          onChangePage={setPage}
          page={page}
        />
      </Row>
    </>
  );
};

export default JobFunction;
