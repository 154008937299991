import { useEffect, useState } from "react";
import { Col, Dropdown, Menu, message, Row, TableColumnsType } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Table from "@components/Table";
import Button from "@components/Button";
import {
  ErrorServices,
  useDeleteSubJobFunctionMutation,
  useFetchJobFunctionByIdQuery,
} from "@services";
import AddJobSubFunction from "./AddJobSubFunction";
import EditJobSubFunction from "./EditJobSubFunction";
import { ReactComponent as MenuIcon } from "@assets/images/vertical-dots.svg";

type SubJobFunctionType = {
  id: number;
  name: string;
};

const SubJobFunction = () => {
  const [isAddJSFVisible, setIsAddJSFVisible] = useState(false);
  const [isEditJSFVisible, setIsEditJSFVisible] = useState(false);
  const [jsfItem, setJsfItem] = useState({});
  const [deleteSubJobFunction] = useDeleteSubJobFunctionMutation();
  const { job_id } = useParams<{ job_id: string }>();

  const { data: jobFunctionData, isLoading: isLoadingJobFunctions } =
    useFetchJobFunctionByIdQuery({ job_id });
  const { data: jobFunction } = jobFunctionData || {};
  const handleActionDropdown = ({
    item,
    key,
    domEvent,
  }: {
    key: string;
    domEvent:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>;
    item: SubJobFunctionType;
  }) => {
    domEvent.stopPropagation();
    if (key === "2") {
      removeSubJobFunction(item?.id.toString());
    } else {
      onEditJSf(item);
    }
  };

  const columns: TableColumnsType<SubJobFunctionType> = [
    {
      title: "Job Sub Function",
      key: "name",
      dataIndex: "name",
      width: `calc(100% - 260px)`,
    },
    {
      title: "Actions",
      key: "action",
      width: 160,
      align: "center",
      render: (item: SubJobFunctionType) => {
        const menu = (
          <Menu
            onClick={({ key, domEvent }) =>
              handleActionDropdown({ item, key, domEvent })
            }
            tabIndex={1}
          >
            <Menu.Item key="1">Edit</Menu.Item>
            <Menu.Item key="2" danger>
              Delete
            </Menu.Item>
          </Menu>
        );
        return (
          <div className="table__action__menu">
            <Dropdown overlay={menu} trigger={["click"]}>
              <MenuIcon
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const onEditJSf = async (item: SubJobFunctionType) => {
    try {
      setJsfItem(item);
      setIsEditJSFVisible(true);
    } catch (error) {
      ErrorServices(error);
    }
  };

  const removeSubJobFunction = async (id: string) => {
    try {
      await deleteSubJobFunction({ id }).unwrap();
    } catch (error) {
      ErrorServices(error);
    }
  };

  return (
    <>
      <AddJobSubFunction
        setIsVisible={setIsAddJSFVisible}
        isVisible={isAddJSFVisible}
        job_function_id={job_id}
      />
      <EditJobSubFunction
        setIsVisible={setIsEditJSFVisible}
        isVisible={isEditJSFVisible}
        jsfItem={jsfItem}
        job_function_id={job_id}
      />
      <Row className="mb-20">
        <Col span={16}>
          <div className="main-heading">Sub-Job Functions</div>
        </Col>
        <Col className="align-end" span={8}>
          <Button variant="add" onClick={() => setIsAddJSFVisible(true)}>
            Add Sub-Job Function
          </Button>
        </Col>
      </Row>

      <Row>
        <Table
          data={jobFunction?.job_sub_functions}
          columns={columns}
          isLoading={isLoadingJobFunctions}
        />
      </Row>
    </>
  );
};

export default SubJobFunction;
