import { FC } from "react";
import { Col, Input, Row } from "antd";
import { useTypedSelector } from "@hooks";

import Modal from "@components/Modal";
import { IModal } from "@types";
import { useFetchComparatorDataQuery } from "@/services";

const InternalComparatorData: FC<IModal> = ({ isVisible, setIsVisible }) => {
  const { offer } = useTypedSelector(state => state?.offers);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data } = useFetchComparatorDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    { skip: !offer?.id }
  );
  const { data: comparatorData } = data || {};
  const { internal_comparator_data } = comparatorData || {};
  const {
    internal_salary_range,
    internal_payroll_grade,
    internal_payroll_function,
  } = internal_comparator_data || {};
  return (
    <Modal
      mode="versions"
      footer={null}
      title="Interal Comparator Data"
      isVisible={isVisible}
      width={855}
      setIsVisible={setIsVisible}
    >
      <div className="offers__modal">
        <div className="offers__modal__row">
          <div className="offers__modal__title">
            {`Internal Salary Range - Grade ${offer?.offer_position_detail?.grade}`}
          </div>
          <Row justify="space-between">
            <Col span={7}>
              <label>Minimum</label>
              <Input
                readOnly
                value={internal_salary_range?.min || ""}
                placeholder="minimum value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Midpoint</label>
              <Input
                readOnly
                value={internal_salary_range?.mid || ""}
                placeholder="midpoint value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Maximum</label>
              <Input
                readOnly
                value={internal_salary_range?.max || ""}
                placeholder="maximum value"
                size="large"
              />
            </Col>
          </Row>
        </div>
        <div className="offers__modal__row">
          <div className="offers__modal__title">
            {`Internal Payroll Spread - Grade ${offer?.offer_position_detail?.grade}`}
          </div>
          <Row justify="space-between">
            <Col span={7}>
              <label>Minimum</label>
              <Input
                readOnly
                value={internal_payroll_grade?.min || ""}
                placeholder="minimum value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Midpoint</label>
              <Input
                readOnly
                value={internal_payroll_grade?.avg || ""}
                placeholder="midpoint value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Maximum</label>
              <Input
                readOnly
                value={internal_payroll_grade?.max || ""}
                placeholder="maximum value"
                size="large"
              />
            </Col>
          </Row>
        </div>
        <div className="offers__modal__row">
          <div className="offers__modal__title">
            Internal Payroll Spread - Functional (Sales)
          </div>
          <Row justify="space-between">
            <Col span={7}>
              <label>Minimum</label>
              <Input
                readOnly
                value={internal_payroll_function?.min || ""}
                placeholder="minimum value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Midpoint</label>
              <Input
                readOnly
                value={internal_payroll_function?.avg || ""}
                placeholder="midpoint value"
                size="large"
              />
            </Col>
            <Col span={7}>
              <label>Maximum</label>
              <Input
                readOnly
                value={internal_payroll_function?.max || ""}
                placeholder="maximum value"
                size="large"
              />
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};

export default InternalComparatorData;
