import { Switch, TableColumnsType, Tag, Popover } from "antd";

import { IUser } from "@types";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";

export const getColumns = ({
  updateStatus,
  updateSubAdmin,
  id,
}: {
  updateStatus: (
    user_id: number,
    name: string,
    is_active: boolean
  ) => Promise<void>;
  id: number;
  updateSubAdmin: (id: number) => void;
}): TableColumnsType<IUser> => [
  {
    title: "name",
    key: "name",
    width: "10%",
    render: (user: IUser) => user?.first_name + " " + user?.last_name,
  },
  {
    title: "email",
    key: "email",
    dataIndex: "email",
    width: "20%",
  },
  {
    title: "phone no.",
    key: "no",
    width: "20%",
    render: (user: IUser) => `+${user?.phone_code} ${user?.phone_number}`,
  },
  {
    title: "business units",
    key: "business_units",
    width: "15%",
    render: (elm: any) => {
      return (
        <div>
          {elm.business_units.length > 0 && (
            <Tag>{elm.business_units[0].name}</Tag>
          )}
          {elm.business_units.length > 1 ? (
            <Popover
              content={
                <div>
                  {elm.business_units.map((item: any) => (
                    <Tag>{item.name}</Tag>
                  ))}
                </div>
              }
            >
              <Tag>+{elm.business_units.length - 1} more</Tag>
            </Popover>
          ) : (
            ""
          )}
        </div>
      );
    },
  },
  {
    title: "regions",
    key: "regions",
    width: "15%",
    render: (elm: any) => {
      return (
        <div>
          {elm.regions.length > 0 && <Tag>{elm.regions[0].name}</Tag>}
          {elm.regions.length > 1 ? (
            <Popover
              content={
                <div>
                  {elm.regions.map((item: any) => (
                    <Tag>{item.name}</Tag>
                  ))}
                </div>
              }
            >
              <Tag>+{elm.regions.length - 1} more</Tag>
            </Popover>
          ) : (
            ""
          )}
        </div>
      );
    },
  },
  {
    title: "countries",
    key: "countries",
    width: "40%",
    render: (elm: any) => {
      return (
        <div>
          {elm.countries.length > 0 && <Tag>{elm.countries[0].name}</Tag>}
          {elm.countries.length > 1 ? (
            <Popover
              content={
                <div>
                  {elm.countries.map((item: any) => (
                    <Tag>{item.name}</Tag>
                  ))}
                </div>
              }
            >
              <Tag>+{elm.countries.length - 1} more</Tag>
            </Popover>
          ) : (
            ""
          )}
        </div>
      );
    },
  },
  ...(!checkPermission(permissions.ENABLE_DISABLE_COMPANY_USER)
    ? []
    : ([
        {
          title: "Active/Inactive",
          width: "10%",
          align: "center",
          render: ({
            is_active,
            first_name,
            last_name,
            id: user_id,
          }: IUser) => {
            return (
              <Switch
                disabled={user_id === id}
                defaultChecked={is_active}
                onChange={(checked: boolean) =>
                  updateStatus(user_id, `${first_name} ${last_name}`, checked)
                }
              />
            );
          },
        },
      ] as any)),
  ...(!checkPermission(permissions.UPDATE_COMPANY_USER)
    ? []
    : [
        {
          title: "Action",
          key: "action",
          width: "10%",
          render: ({ id }: IUser) => {
            return (
              <div>
                <span
                  onClick={() => updateSubAdmin(id)}
                  className="table__action__btn table__action__btn--client"
                >
                  Edit
                </span>
              </div>
            );
          },
        },
      ]),
];
