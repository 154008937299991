import { Button, Collapse, message, Row } from "antd";
import { useState, useRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./salaryOfferModeller.less";
import { ReactComponent as UpIcon } from "@assets/images/up-with-bg.svg";
import { ReactComponent as DownIcon } from "@assets/images/down-with-bg.svg";
import { panels } from "./config";
import {
  ErrorServices,
  useCreateOfferModellerMutation,
  useFetchAutoPopulateDataQuery,
  useUpdateOfferStatusMutation,
} from "@services";
import { useTypedSelector } from "@hooks";
import { paths } from "@router";
import { handleStage, offerModellerInitialState } from "@store/offers";

const { Panel } = Collapse;

const genExtra = (id: string, activeKeys: string | string[]) => (
  <div className="expand-icon">
    <span>{`View ${activeKeys?.includes(id) ? "Less" : "More"}`}</span>
    {activeKeys?.includes(id) ? <UpIcon /> : <DownIcon />}
  </div>
);

const SalaryOfferModeller = () => {
  const [activekeys, setActiveKeys] = useState<string[] | string>(["1"]);
  const dispatch = useDispatch();
  const draft = useRef<boolean>(false);
  const accept = useRef<boolean>(false);
  const { offer } = useTypedSelector(state => state?.offers);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const history = useHistory();
  const { offerModeller } = useTypedSelector(state => state.offers);
  const [createOfferModeller, { isLoading: isCreating }] =
    useCreateOfferModellerMutation();
  const [updateOfferStatus, { isLoading: isUpdating }] =
    useUpdateOfferStatusMutation();
  useFetchAutoPopulateDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !offer?.id,
    }
  );

  const onChange = (keys: string[] | string) => {
    setActiveKeys(keys);
  };

  const handleSubmit = async (is_draft: boolean) => {
    try {
      draft.current = is_draft;
      await createOfferModeller({
        company_id,
        id: offer?.id,
        body: { ...offerModeller, is_draft },
      }).unwrap();
      message.success(`Offer ${is_draft ? "saved" : "placed"} successfully`);
      if (!is_draft) {
        history.push(paths.client.offers.active);
      }
    } catch (err) {
      console.log(err);
      // ErrorServices(err);
    }
  };

  const update = async (is_accepted: boolean) => {
    try {
      accept.current = is_accepted;
      await updateOfferStatus({
        company_id,
        id: offer?.id,
        status: is_accepted ? "ACCEPTED" : "REJECTED",
      }).unwrap();
      message.success(`Offer ${is_accepted ? "Accepted" : "Rejected"}!`);
      history.push(paths.client.offers.active);
    } catch (err) {
      console.log(err);
      ErrorServices(err);
    }
  };

  return (
    <>
      <Collapse
        activeKey={activekeys}
        defaultActiveKey={["1"]}
        onChange={onChange}
        expandIconPosition="right"
        ghost={true}
      >
        {panels?.map(
          ({
            id,
            header,
            component,
          }: {
            id: string;
            header: string;
            component: any;
          }) => (
            <Panel
              showArrow={false}
              header={header}
              key={id}
              extra={genExtra(id, activekeys)}
            >
              {component}
            </Panel>
          )
        )}
      </Collapse>
      <div className="transparent height-40" />
      <Row className="offers__form__fields__btns salary-offer__btns transparent">
        {window.location.pathname?.includes("/create") ||
        offer?.status === "DRAFTED" ? (
          <>
            <Button
              onClick={() => handleSubmit(false)}
              size="large"
              type="primary"
            >
              {isCreating && !draft?.current ? (
                <LoadingOutlined className="spinner" />
              ) : (
                "Place Offer"
              )}
            </Button>
            <Button
              onClick={() => handleSubmit(true)}
              size="large"
              className="secondary-btn"
            >
              {isCreating && draft?.current ? (
                <LoadingOutlined className="spinner" />
              ) : (
                "Save as draft"
              )}
            </Button>
            <Button
              onClick={() => dispatch(handleStage("CANDIDATE_DETAILS"))}
              size="large"
              className="secondary-btn"
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            {offer?.status === "PLACED" ? (
              <>
                <Button
                  onClick={() => update(true)}
                  size="large"
                  type="primary"
                >
                  {isUpdating && accept?.current ? (
                    <LoadingOutlined className="spinner" />
                  ) : (
                    "Accept"
                  )}
                </Button>
                <Button
                  onClick={() => update(false)}
                  size="large"
                  className="secondary-btn"
                >
                  {isUpdating && !accept?.current ? (
                    <LoadingOutlined className="spinner" />
                  ) : (
                    "Reject"
                  )}
                </Button>
              </>
            ) : null}

            <Button
              onClick={() => history.goBack()}
              size="large"
              className="secondary-btn"
            >
              Cancel
            </Button>
          </>
        )}
      </Row>
    </>
  );
};

export default SalaryOfferModeller;
